<template>
    <div>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
        <div class="filter-container">
            <div class="left-content">
                <c-limit-filter ref="budgetHeader" @chgData="chgData" nameYear="Прогнозный год"></c-limit-filter>
            </div>
            <div class="right-content">
                <div class="filter-actions">
                    <b-button variant="success" @click="saveDataToDB('all')" :disabled="!attributeStatus">Сохранить</b-button>
                </div>
            </div>
        </div>
        <div class="filter-breadcrumb">
                <span class="item-breadcrumb" v-if="curYear" @click="openFilterByRef('curYearRef')">
                    {{ curYear }}-{{ curYear + 2 }}
                </span>
                <span class="item-breadcrumb" v-if="currentRegion" @click="openFilterByRef('curRegionRef')">
                    {{ currentRegion }}-{{ regionName }}
                </span>
                <span class="item-breadcrumb" v-if="funcGroup" @click="openFilterByRef('grRef')">
                    {{ funcGroup.gr }}-{{funcGroup.name_ru}}
                </span>
            <span class="item-breadcrumb" v-if="curAbp" @click="openFilterByRef('abpRef')">
                    {{curAbp}}-АБП
            </span>
            <span class="item-breadcrumb" v-if="curProg" @click="openFilterByRef('prgRef')">
                    {{curProg}}-БП
            </span>
            <span class="item-breadcrumb" v-if="curSubProg" @click="openFilterByRef('pprRef')">
                    {{curSubProg}}-БПП
            </span>
        </div>

        <b-progress variant="primary" v-show="bar<100" height="3px" :value="bar" striped animated></b-progress>
        <div class="table-container">
            <div class="b-table-sticky-header table-responsive-true">
                <table class="table b-table table-bordered">
                    <thead>
                        <tr>
                            <th>КОД</th>
                            <th>НАИМЕНОВАНИЕ</th>
                            <th>ИСПОЛНЕНИЕ ЗА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)-2 }} ГОД</th>
                            <th>УТОЧНЕННЫЙ ПЛАН НА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)-1 }}Г. (БЕЗ РБ)</th>
                            <th>В Т.Ч. РАЗОВЫЕ РАСХОДЫ</th>
                            <th>УТОЧНЕННЫЙ ПЛАН НА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)-1 }} ГОД (БЕЗ УЧЕТА РАЗОВЫХ РАСХОДОВ)</th>
                            <th>ПРОГНОЗ НА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear) }}</th>
                            <th>В Т.Ч. РАСХОДЫ, УЧТЕННЫЕ СВЕРХ ПАРАМЕТРОВ</th>
                            <th>% РОСТА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear) }}</th>
                            <th>ПРОГНОЗ НА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+1 }} ГОД</th>
                            <th>% РОСТА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+1 }} ГОД</th>
                            <th>ПРОГНОЗ НА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+2 }} ГОД</th>
                            <th>% РОСТА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+2 }} ГОД</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td></td>
                        <td><b>Всего затрат</b></td>
                        <td class="text-right"><b>{{ $n(factSum, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ $n(planSum, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ $n(oneTimeExpenses, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ $n(refinedPlan, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ $n(forecastYearPlusOne, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ $n(expensesInExcessOfParameters, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ isNaN(parseFloat(percentOfGrowth))===true ? '0.00' : isFinite(parseFloat(percentOfGrowth)) ? $n(percentOfGrowth, 'decimal') : '0.00' }}</b></td>
                        <td class="text-right"><b>{{ isNaN(parseFloat(twoYearPlusForecast))===true ? '0.00' : isFinite(parseFloat(twoYearPlusForecast)) ? $n(twoYearPlusForecast, 'decimal') : '0.00' }}</b></td>
                        <td class="text-right"><b>{{ isNaN(parseFloat(twoYearPlusPercent))===true ? '0.00' : isFinite(parseFloat(twoYearPlusPercent)) ? $n(twoYearPlusPercent, 'decimal') : '0.00' }}</b></td>
                        <td class="text-right"><b>{{ isNaN(parseFloat(threeYearPlusForecast))===true ? '0.00' : isFinite(parseFloat(threeYearPlusForecast)) ? $n(threeYearPlusForecast, 'decimal') : '0.00' }}</b></td>
                        <td class="text-right"><b>{{ isNaN(parseFloat(threeYearPlusPercent))===true ? '0.00' : isFinite(parseFloat(threeYearPlusPercent)) ? $n(threeYearPlusPercent, 'decimal') : '0.00' }}</b></td>
                    </tr>
                    <template v-for="(budgetItem, budgetIndex) of budgetFactPlanArr">
                        <tr :key="'budgetItemIndx'+budgetIndex">
                            <td>
                                <span class="icon icon-document-pencil" @click="getUtilityServices(true, budgetItem)" v-if="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf">{{ budgetItem.spf }}</span>
                                <span class="icon icon-document-pencil" @click="getOtherServices(true, budgetItem)" v-else-if="budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf">{{ budgetItem.spf }}</span>
                                <span v-else>{{ budgetItem.spf }}</span>
                            </td>
                            <td>{{ budgetItem.title }}</td>
                            <td class="text-right">{{ budgetItem.factSum }}</td>
                            <td class="text-right">{{ budgetItem.planSum }}</td>
                            <td>
                                <b-form-input
                                    class="text-right"
                                    :value="isNaN(parseFloat(budgetItem.oneTimeExpenses))===true || isFinite(parseFloat(budgetItem.oneTimeExpenses)) || !isValueEmpty(budgetItem.oneTimeExpenses) ? parseFloat(budgetItem.oneTimeExpenses).toFixed(2) : parseFloat('0').toFixed(2)"
                                    @change="val => changeCellOneTimeExpenses(budgetItem, val)"
                                    @keyup.enter.exact="keyup13"
                                    @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                    @blur="inputFixed(budgetItem, 'oneTimeExpenses', budgetItem.oneTimeExpenses, 2)"
                                    :disabled="!attributeStatus"
                                >
                                </b-form-input>
                            </td>
                            <td class="text-right">
                                {{ budgetItem.refinedPlan }}
                            </td>
                            <td>
                                <template v-if="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf">
                                    {{ isNaN(parseFloat(budgetItem.forecastYearPlusOne))===true || isFinite(parseFloat(budgetItem.forecastYearPlusOne)) || budgetItem.forecastYearPlusOne==='' ? parseFloat(budgetItem.forecastYearPlusOne).toFixed(2) : parseFloat('0').toFixed(2) }}
                                </template>
                                <template v-else>
                                    <span class="tempText">{{ budgetItem.separateSpecificOneYear }}</span><br>
                                    <b-form-input
                                        class="text-right"
                                        :value="isNaN(parseFloat(budgetItem.forecastYearPlusOne))===true || isFinite(parseFloat(budgetItem.forecastYearPlusOne)) || budgetItem.forecastYearPlusOne==='' ? parseFloat(budgetItem.forecastYearPlusOne).toFixed(2) : parseFloat('0').toFixed(2)"
                                        @change="val => changeCellForecast(budgetItem, val)"
                                        @keyup.enter.exact="keyup13"
                                        @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                        @blur="inputFixed(budgetItem, 'forecastYearPlusOne', budgetItem.forecastYearPlusOne, 2)"
                                        :readonly="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf"
                                        :disabled="!attributeStatus"
                                    >
                                    </b-form-input>
                                </template>
                            </td>
                            <td>
                                <b-form-input
                                    class="text-right"
                                    :value="isNaN(parseFloat(budgetItem.expensesInExcessOfParameters))===true || isFinite(parseFloat(budgetItem.expensesInExcessOfParameters)) || budgetItem.expensesInExcessOfParameters==='' ? parseFloat(budgetItem.expensesInExcessOfParameters).toFixed(2) : parseFloat('0').toFixed(2)"
                                    @change="val => changeCellExpenses(budgetItem, val)"
                                    @keyup.enter.exact="keyup13"
                                    @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                    @blur="inputFixed(budgetItem, 'expensesInExcessOfParameters', budgetItem.expensesInExcessOfParameters, 2)"
                                    :disabled="!attributeStatus"
                                >
                                </b-form-input>
                            </td>
                            <td class="text-right">
                                {{ budgetItem.percentOfGrowth }}
                            </td>
                            <td>
                                <template v-if="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf">
                                    {{ isNaN(parseFloat(budgetItem.twoYearPlusForecast))===true || isFinite(parseFloat(budgetItem.twoYearPlusForecast)) || budgetItem.twoYearPlusForecast==='' ? parseFloat(budgetItem.twoYearPlusForecast).toFixed(2) : parseFloat('0').toFixed(2) }}
                                </template>
                                <template v-else>
                                    <span class="tempText">{{ budgetItem.separateSpecificTwoYear }}</span><br>
                                    <b-form-input
                                        class="text-right"
                                        :value="isNaN(parseFloat(budgetItem.twoYearPlusForecast))===true || isFinite(parseFloat(budgetItem.twoYearPlusForecast)) || budgetItem.twoYearPlusForecast==='' ? parseFloat(budgetItem.twoYearPlusForecast).toFixed(2) : parseFloat('0').toFixed(2)"
                                        @keyup.enter.exact="keyup13"
                                        @change="val => changeCellForecastTwo(budgetItem, val)"
                                        @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                        @blur="inputFixed(budgetItem, 'twoYearPlusForecast', budgetItem.twoYearPlusForecast, 2)"
                                        :readonly="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf"
                                        :disabled="!attributeStatus"
                                    >
                                    </b-form-input>
                                </template>
                            </td>
                            <td  class="text-right">
                                {{ budgetItem.twoYearPlusPercent }}
                            </td>
                            <td>
                                <template v-if="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf">
                                    {{ isNaN(parseFloat(budgetItem.threeYearPlusForecast))===true || isFinite(parseFloat(budgetItem.threeYearPlusForecast)) || budgetItem.twoYearPlusForecast==='' ? parseFloat(budgetItem.threeYearPlusForecast).toFixed(2) : parseFloat('0').toFixed(2) }}
                                </template>
                                <template v-else>
                                    <span class="tempText">{{ budgetItem.separateSpecificThreeYear }}</span><br>
                                    <b-form-input
                                        class="text-right"
                                        :value="isNaN(parseFloat(budgetItem.threeYearPlusForecast))===true || isFinite(parseFloat(budgetItem.threeYearPlusForecast)) || budgetItem.twoYearPlusForecast==='' ? parseFloat(budgetItem.threeYearPlusForecast).toFixed(2) : parseFloat('0').toFixed(2)"
                                        @change="val => changeCellForecastThree(budgetItem, val)"
                                        @keyup.enter.exact="keyup13"
                                        @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                        @blur="inputFixed(budgetItem, 'threeYearPlusForecast', budgetItem.threeYearPlusForecast, 2)"
                                        :readonly="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf"
                                        :disabled="!attributeStatus"
                                    >
                                    </b-form-input>
                                </template>
                            </td>
                            <td  class="text-right">
                                {{ budgetItem.threeYearPlusPercent }}
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
            <!-- Модальное окном для 151 формы -->
            <b-modal
                v-model="modalUtilityServicesVisible"
                modal-class="modal-table add-abp"
                size="lg"
                title=""
                centered
            >
                        <div class="table-container">
                            <div class="b-table-sticky-header table-responsive-true">
                                <table class="table b-table table-bordered b-table-no-border-collapse">
                                    <thead>
                                    <tr>
                                        <th class="utilityServices">№</th>
                                        <th class="utilityServices">Наименование ком.услуг</th>
                                        <th class="utilityServices">Расчетные данные из форм расчетов за {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)-1 }} год</th>
                                        <th class="utilityServices">Прогноз {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear) }}</th>
                                        <th class="utilityServices">Прогноз {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+1 }}</th>
                                        <th class="utilityServices">Прогноз {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+2 }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-for="(commItem, commIndex) of commServicesArr">
                                    <tr :key="'commItemIndx'+commIndex" class="trTdCommService">
                                        <td>{{ commItem.index }}</td>
                                        <td>{{ commItem.nameRu }}</td>
                                        <td>
                                            <b-form-input
                                                class="text-right inputCommService"
                                                :value="parseFloat(commItem.value).toFixed(2)"
                                                readonly
                                            >
                                            </b-form-input>
                                        </td>
                                        <td>
                                            <span class="tempText">{{ commItem.valOne }}</span>
                                            <b-form-input
                                                class="text-right inputCommService"
                                                :value="isNaN(parseFloat(commItem.countFirstVal))===true || isFinite(parseFloat(commItem.countFirstVal)) || isValueEmpty(commItem.countFirstVal) ? parseFloat(commItem.countFirstVal).toFixed(2) : parseFloat('0').toFixed(2)"
                                                @change="val => changeFirstYearVal(commItem, val, 'commService')"
                                                @keyup.enter.exact="keyup13"
                                                @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                                @blur="inputFixed(commItem, 'countFirstVal', commItem.countFirstVal, 2)"
                                                :disabled="!attributeStatus"
                                            >
                                            </b-form-input>
                                        </td>
                                        <td>
                                            <span class="tempText">{{ commItem.secondVal }}</span>
                                            <b-form-input
                                                class="text-right inputCommService"
                                                :value="isNaN(parseFloat(commItem.countSecondVal))===true || isFinite(parseFloat(commItem.countSecondVal)) || isValueEmpty(commItem.countSecondVal) ? parseFloat(commItem.countSecondVal).toFixed(2) : parseFloat('0').toFixed(2)"
                                                @change="val => changeSecondYearVal(commItem, val, 'commService')"
                                                @keyup.enter.exact="keyup13"
                                                @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                                @blur="inputFixed(commItem, 'countSecondVal', commItem.countSecondVal, 2)"
                                                :disabled="!attributeStatus"
                                            >
                                            </b-form-input>
                                        </td>
                                        <td>
                                            <span class="tempText">{{ commItem.thirdVal }}</span>
                                            <b-form-input
                                                class="text-right inputCommService"
                                                :value="isNaN(parseFloat(commItem.countThirdVal))===true || isFinite(parseFloat(commItem.countThirdVal)) || isValueEmpty(commItem.countThirdVal) ? parseFloat(commItem.countThirdVal).toFixed(2) : parseFloat('0').toFixed(2)"
                                                @change="val => changeThirdYearVal(commItem, val, 'commService')"
                                                @keyup.enter.exact="keyup13"
                                                @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                                @blur="inputFixed(commItem, 'countThirdVal', commItem.countThirdVal, 2)"
                                                :disabled="!attributeStatus"
                                            >
                                            </b-form-input>
                                        </td>
                                    </tr>
                                    </template>
                                    <tr>
                                        <td></td>
                                        <td>Итого</td>
                                        <td>{{ $n(totalValCount, 'decimal')}}</td>
                                        <td>{{ $n(totalFirstCount, 'decimal')}}</td>
                                        <td>{{ $n(totalSecondCount, 'decimal')}}</td>
                                        <td>{{ $n(totalThirdCount, 'decimal')}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <template #modal-footer>
                            <div>
                                <b-button variant="success" size="sm" class="mr-2" @click="saveUtilityServices(true)" :disabled="!attributeStatus">Сохранить</b-button>
                                <b-button variant="secondary" size="sm" @click="saveUtilityServices(false)">Отменить</b-button>
                            </div>
                        </template>
            </b-modal>
            <!-- Модальное окном для 159 формы -->
            <b-modal
                v-model="modalOtherServicesVisible"
                modal-class="modal-table add-abp"
                size="lg"
                title=""
                centered
            >
                <div class="table-container">
                    <div class="b-table-sticky-header table-responsive-true">
                        <div v-if="isWarning" class="warning-message">{{ warningTxt }}</div>
                        <table class="table b-table table-bordered b-table-no-border-collapse">
                            <thead>
                            <tr>
                                <th class="utilityServices">№</th>
                                <th class="utilityServices">Наименование ком.услуг</th>
                                <th class="utilityServices">Расчетные данные за {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)-1 }} год</th>
                                <th class="utilityServices">Прогноз {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear) }}</th>
                                <th class="utilityServices">Прогноз {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+1 }}</th>
                                <th class="utilityServices">Прогноз {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+2 }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(commGarbageItem, commGarbageIndex) of form159">
                                <tr :key="'commGarbageIndex'+commGarbageIndex" class="trTdCommService">
                                    <td>{{ commGarbageItem.index }}</td>
                                    <td>{{ commGarbageItem.nameRu }}</td>
                                    <td>
                                        <b-form-input
                                            class="text-right inputCommService"
                                            :value="parseFloat(commGarbageItem.value).toFixed(2)"
                                            readonly
                                        >
                                        </b-form-input>
                                    </td>
                                    <td>
                                        <span class="tempText">{{ commGarbageItem.firstVal }}</span>
                                        <b-form-input
                                            class="text-right inputCommService"
                                            :value="isNaN(parseFloat(commGarbageItem.countFirstVal))===true || isFinite(parseFloat(commGarbageItem.countFirstVal)) || isValueEmpty(commGarbageItem.countFirstVal) ? parseFloat(commGarbageItem.countFirstVal).toFixed(2) : parseFloat('0').toFixed(2)"
                                            @change="val => changeFirstYearVal(commGarbageItem, val, 'garbageService')"
                                            @keyup.enter.exact="keyup13"
                                            @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                            @blur="inputFixed(commGarbageItem, 'countFirstVal', commGarbageItem.countFirstVal, 2)"
                                            :disabled="!attributeStatus"
                                        >
                                        </b-form-input>
                                    </td>
                                    <td>
                                        <span class="tempText">{{ commGarbageItem.secondVal }}</span>
                                        <b-form-input
                                            class="text-right inputCommService"
                                            :value="isNaN(parseFloat(commGarbageItem.countSecondVal))===true || isFinite(parseFloat(commGarbageItem.countSecondVal)) || isValueEmpty(commGarbageItem.countSecondVal) ? parseFloat(commGarbageItem.countSecondVal).toFixed(2) : parseFloat('0').toFixed(2)"
                                            @change="val => changeSecondYearVal(commGarbageItem, val, 'garbageService')"
                                            @keyup.enter.exact="keyup13"
                                            @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                            @blur="inputFixed(commGarbageItem, 'countSecondVal', commGarbageItem.countSecondVal, 2)"
                                            :disabled="!attributeStatus"
                                        >
                                        </b-form-input>
                                    </td>
                                    <td>
                                        <span class="tempText">{{ commGarbageItem.thirdVal }}</span>
                                        <b-form-input
                                            class="text-right inputCommService"
                                            :value="isNaN(parseFloat(commGarbageItem.countThirdVal))===true || isFinite(parseFloat(commGarbageItem.countThirdVal)) || isValueEmpty(commGarbageItem.countThirdVal) ? parseFloat(commGarbageItem.countThirdVal).toFixed(2) : parseFloat('0').toFixed(2)"
                                            @change="val => changeThirdYearVal(commGarbageItem, val, 'garbageService')"
                                            @keyup.enter.exact="keyup13"
                                            @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                            @blur="inputFixed(commGarbageItem, 'countThirdVal', commGarbageItem.countThirdVal, 2)"
                                            :disabled="!attributeStatus"
                                        >
                                        </b-form-input>
                                    </td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>

                <template #modal-footer>
                    <div>
                        <b-button variant="success" size="sm" class="mr-2" @click="saveOtherServices(true)" :disabled="!attributeStatus">Сохранить</b-button>
                        <b-button variant="secondary" size="sm" @click="saveOtherServices(false)">Отменить</b-button>
                    </div>
                </template>
            </b-modal>

        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import CLimitFilter from '@/modules/budget-limitCosts/LimitFilter.vue';
import store from '../../services/store';
import {isNull, parseInt} from "lodash";
import VueElementLoading from 'vue-element-loading';

const {Ax} = require("../../utils");

export default {
    name: 'budget-limits',
    components: {
        'c-limit-filter': CLimitFilter,
        'loading': VueElementLoading
    },
    data() {
        return {
            totalField: [
                {
                    key: 'total',
                    label: 'Всего затрат'
                }
            ],
            tempAr: [   // -----временные данные, которые нужно будет удалить и добавить в таблицу справочника 'budget_separate_specifics'
                {
                    id: '650471',
                    key: '',
                    indicator: 'Годовая фактическая потребность на выплату заработной платы',
                    code: 'annwage',
                    kato: '550000000'
                },
                {
                    id: '650476',
                    key: 'tariffutilit',
                    indicator: 'Рост тарифов по коммунальным услугам',
                    code: '',
                    kato: '550000000'
                }
            ],
            inflationItems: {
                'yearOne': '',
                'yearOneVal': '',
                'yearTwo': '',
                'yearTwoVal': '',
                'yearThree': '',
                'yearThreeVal': ''
            },
            specificItems: [],
            inflationIndex: '',     // -------переменная для хранения индекса инфляции на прогнозный год
            separateSpecific: [],  // --------Глобальный массив для хранения исключений по отдельным спецификам
            budgetFactPlanArr: [],  // ------основной массив
            specificsObj: [],
            budgetLimitForm: [],
            factArrList: [],
            planArrList: [],
            uniqSpf: [],
            executionForMinusTwoForecastYears: [],
            curHeader: null,
            header: null,
            chosenYear: null,
            spfArrFact: [],
            spfArrFin: [],
            year: null,
            curYear: null,
            curAbp: null,
            curProg: null,
            funcGroup: null,
            curSubProg: null,
            obl: null,
            region: null,
            abp: null,
            prg: null,
            ppr: null,
            constForecastYearPlusOne: '',
            constTwoYearPlusForecast: '',
            constThreeYearPlusForecast: '',
            bar: 0,
            editing: false,
            firstFactPlanMap: new Map(),
            firstFactPlanMapSec: new Map(),
            firstFactPlanObject: {},
            loading: false,
            leavePage: false,
            prognozOne: false,
            prognozTwo: false,
            prognozThree: false,
            executionTotal: null,
            firstFactPlanArray: [],
            informationArr: [],
            fullInformationListArr: [],
            isArrayFilled: false,
            listOfSpf: null,
            currentRegion: null,
            regionName: null,
            sumPrognoz: {
                'first': 0,
                'second': 0,
                'third': 0
            },
            spfSpecific: {
                'spf111': parseInt(111),
                'spf112': parseInt(112)
            },
            spfSpecificModal: {
                'modalSpecifics': [
                    {
                        spf: parseInt(151),
                        title: ''
                    },
                    {
                        spf: parseInt(159),
                        title: ''
                    }
                ]
            },
            modalUtilityServicesVisible: false,
            modalOtherServicesVisible: false,
            commServicesArr: [],
            spf151ObjWithTotalResults: {},
            spf159ObjWithTotalResults: {},
            limitsFormCommServicesArr: [],
            limitFormGarbageServicesArr: [],
            form159: [],
            warningTxt: 'Лимит по оплате вывоза ТБО превышает лимит по оплате прочих услуг и работ. Проверьте данные.',
            dictNormativeParams: ["OPVR", "OPV", "SN", "SO", "OSMS"],
            spf116: 116,
            spf121: 121,
            spf122: 122,
            spf124: 124,
            attributeStatus: true,
            accessLevel: 1
        };
    },
    async mounted() {
        await this.getObl();
    },
    methods: {
        //----В начале проверяется количество кликов по кнопке "Открыть" через счетчик
        async chgData(data) {
            this.accessLevel = await this.getUserAccess(this.userId);
            if (data != null) {
                await this.filterParams(data);
            }
        },
        // ----Извлекаются параметры из меню фильтрации
        async filterParams(data) {
            if (data !== null) {
                this.curHeader = data;
                if ((this.curHeader !== null)
                    && (this.curHeader.year !== null)
                    && (this.curHeader.gr !== null)
                    && (this.curHeader.prg !== null)
                    && (this.curHeader.region !== null)
                    && (this.curHeader.regionDetails !== null)) {
                    this.budgetFactPlanArr = [];
                    this.header = {};
                    this.currentRegion = this.curHeader.region;
                    this.curHeader.region = regionsForMSU(this.curHeader.region);
                    this.header = {
                        year: this.curHeader.year,
                        abp: this.curHeader.abp.abp,
                        prg: this.curHeader.prg.prg,
                        ppr: (this.curHeader.pgr === null ? null : this.curHeader.pgr.ppr),
                        curRegion: this.curHeader.region,
                        regDetails: this.curHeader.regionDetails.code
                    };
                    this.chosenYear = this.header.year;
                    this.curYear = this.header.year;
                    this.funcGroup = this.curHeader.gr;
                    this.curAbp = this.header.abp;
                    this.curProg = this.header.prg;
                    this.curSubProg = this.header.ppr;
                    this.bar = 40;
                    this.regionName = this.header.regDetails.name_ru;
                    //---Извлечение всего списка из таблицы "budget_form_limits" по году---//
                    await this.getListOfFormLimit(this.header.year);
                    await this.getIndexOfInflationIndicators(this.header);
                    await this.getBudgetVariantsAttribute(this.header);
                    const obl = this.obl + '0000';
                    const region = this.currentRegion;
                    this.$emit('chgLimitData', this.header, obl, region, this.informationArr);
                }
            }

            function regionsForMSU(region){
                const regionEnd = '01';
                const sizeOfWord = region.length;
                if (!region.endsWith('01')) {
                    const startIndx = parseInt(sizeOfWord) - regionEnd.length;
                    const firstPart = region.split('', startIndx).join('');
                    const secondPart = region.substring(startIndx, region.length);
                    const thirdPart = secondPart.replace(secondPart, regionEnd);
                    region = firstPart.toString() + thirdPart.toString();
                }
                return region;
            }
        },
        //----Извлечь список индекса инфляции из БД по каждому году
        async getIndexOfInflationIndicators(header) {
            const firstYears = header.year;
            const secondYears = header.year + 1;
            const thirdYears = header.year + 2;
            this.specificsObj = [];
            let specificArr = [];

            const fullSpecificArr = await this.getListOfSpecificAndInflation(firstYears, thirdYears);
            const coefficients = await this.getListOfSpecificWithNormatives(); //---Коэффициенты для специфик: 116, 121, 122, 124
            if (fullSpecificArr.length>0){
                // console.log('fullSpecificArr: ' + JSON.stringify(fullSpecificArr));
                for (const specific of fullSpecificArr){
                    let firstYearVal = 0;
                    let secondYearVal = 0;
                    let thirdYearVal = 0;
                    let commServiceItems = [];
                    if (specific.inputFormData!==null && specific.inputFormData.length > 0) {
                        if (parseInt(specific.spf) === parseInt(this.spfSpecificModal.modalSpecifics[0].spf) || parseInt(specific.spf)===parseInt(this.spfSpecificModal.modalSpecifics[1].spf)) {
                            commServiceItems = specific.inputFormData;
                            // console.log('commServiceItems: ', JSON.stringify(commServiceItems));
                        } else {
                            const specificInputForm = getOnlyObjects(specific.inputFormData); //---очистка массива от дублей

                            for (const form of specificInputForm) {
                                if (parseInt(new Date(form.date).getFullYear()) === parseInt(firstYears)) {
                                    firstYearVal = form.value;
                                } else if (parseInt(new Date(form.date).getFullYear()) === parseInt(secondYears)) {
                                    secondYearVal = form.value;
                                } else if (parseInt(new Date(form.date).getFullYear()) === parseInt(thirdYears)) {
                                    thirdYearVal = form.value;
                                }
                            }
                        }
                    } else {
                        if (parseInt(specific.spf) === parseInt(this.spfSpecificModal.modalSpecifics[0].spf) || parseInt(specific.spf)===parseInt(this.spfSpecificModal.modalSpecifics[1].spf)) {
                            commServiceItems = [];
                        }
                    }

                    const obj = {
                        spf: specific.spf!==null ? specific.spf : '',
                        indicatorId: specific.dictIndicatorId.id,
                        kato: specific.regionId.code_kato,
                        title: specific.dictIndicatorId.name_ru,
                        firstYear: firstYears,
                        valYearOne: firstYearVal,
                        secondYear: secondYears,
                        valYearTwo: secondYearVal,
                        thirdYear: thirdYears,
                        valYearThree: thirdYearVal,
                        listSPFspecific: "false",
                        specific: "false",
                        status: specific.status,
                        commServiceList: commServiceItems
                    }

                    specificArr.push(obj);
                }
            }
            // console.log('specificArr: ' + JSON.stringify(specificArr));
            const inflationObj = getInflationObj(specificArr);  //----Получаем объект с пустым spf
            // console.log('inflationObj_Check: ' + JSON.stringify(inflationObj));

            specificArr = embeddedCoefficientsToSpecifics(specificArr, coefficients);//----Добавляет коэффициенты к спецификам: 116, 121, 122, 124
            specificArr = specificArr.filter((item) => parseInt(item.spf) !== parseInt(131) && parseInt(item.spf) !== parseInt(132));
            this.informationArr = specificArr;  //---Убираю лишнее во вложенном массиве commServiceList для spf159
            await this.getFieldsOfFactAndFin(header, inflationObj);

            //----Получаем объект с пустым spf
            function getInflationObj(specificArr) {
                if (specificArr.length > 0){
                    for (const infl of specificArr){
                        if (infl.spf === ''){
                            return infl;
                        }
                    }
                }
                return {};
            }
            // -----Функция для очистки массива от дубликатов
            function getOnlyObjects(arr) {
                const filteredObjectArr = arr.reduce((enteredArr, current) => {
                    const x = enteredArr.find(item => item.date === current.date && item.value === current.value && item.inputFormIndicatorId === current.inputFormIndicatorId);
                    if (!x) {
                        return enteredArr.concat([current]);
                    } else {
                        return enteredArr;
                    }
                }, []);
                return filteredObjectArr;
            }
            //----Добавляет коэффициенты к спецификам: 116, 121, 122, 124
            function embeddedCoefficientsToSpecifics(specifics, coefficients) {
                if (specifics.length > 0) {
                    for (const specific of specifics) {
                        if (isCoefficientForSpecific(specific.spf)){
                            specific.coefficients = coefficients;
                        }
                    }
                }
                return specifics;
            }
            //---Справочник spf к которым относятся коэффициенты
            function isCoefficientForSpecific(spf){
                switch (spf){
                    case 116:
                    case 121:
                    case 122:
                    case 124:
                        return true;
                    default:
                        return false;
                }
            }
        },

        async getBudgetVariantsAttribute(header){
            const region = this.currentRegion;
            const year = header.year;
            const response = await fetch('/api/budget-variants/attribute/'+year+'/'+region);
            const items = await response.json();
            // console.log('items: ' + JSON.stringify(items));
            // console.log('this.accessLevel: ' + this.accessLevel);
            if (!items){
                this.makeToast('Warning', 'Первая прогнозная версия бюджета не актуальна. Вы не можете изменять данные');
            }
            if (this.accessLevel === 2) {
                if (items === true) {
                    this.attributeStatus = true;
                } else {
                    this.attributeStatus = false;
                }
            } else {
                this.attributeStatus = false;
            }
            return items;
        },

        async getUserAccess(userId){
            let accessLevel = '';
            const modules = '004.001.003';
            const url = '/api-py/user-modules/'+userId;
            const response = await fetch(url);
            const items = await response.json();
            const access = getAccess(items, modules);
            accessLevel = access.access_level
            function getAccess(items, modules){
                const res = {};
                if (items.length > 0){
                    for (const item of items){
                        if (item.modules === modules){
                            return item;
                        }
                    }
                }
                return res;
            }
            return accessLevel;
        },

        //---Извлекается список специфик и инфляции
        async getListOfSpecificAndInflation(firstYears, thirdYears){
            /*
                Извлекаются все специфики согласно параметрам
            * */
            const region = this.getRegionForKato(this.header.regDetails);
            const response = await fetch('/api/specifics/all/'+firstYears+'/'+thirdYears+'/'+region);
            const items = await response.json();
            // console.log('items: ' + JSON.stringify(items));
            const specificArr = [];
            const fullSpecificList = [];
            let inflation = {};
            if (items.length > 0){
                for (const item of items){
                    if (item.spf === null){
                        inflation = item;
                    } else {
                        specificArr.push(item);
                    }
                    fullSpecificList.push(item);
                }
            }
            // console.log('fullSpecificList: ' + JSON.stringify(fullSpecificList));
            return fullSpecificList;
        },
        //---Получить нормативные значения для специфик: 116, 121, 122, 124
        async getListOfSpecificWithNormatives() {
            let opvr = '';
            let opv = '';
            let sn = '';
            let so = '';
            let osms = '';
            const year = this.header.year;
            // const year = 2021
            if (this.dictNormativeParams.length > 0){
                for (const normative of this.dictNormativeParams){
                    if (normative==='OPVR'){
                        opvr = normative;
                    } else if (normative==='OPV'){
                        opv = normative;
                    } else if (normative==='SN'){
                        sn = normative;
                    } else if (normative==='SO'){
                        so = normative;
                    } else if (normative==='OSMS'){
                        osms = normative
                    }
                }
            }
            const response = await fetch('/api/specifics/normatives/'+opvr+'/'+opv+'/'+ sn+'/'+so+'/'+osms+'/'+year);
            const items = await response.json();
            const getCodesOfNormatives = getCodesAndNames(items);
            return getUniqNormativeObjects(getCodesOfNormatives, items, year);

            function getCodesAndNames(items){
                const arr = [];
                if (items.length > 0){
                    for (const item of items){
                        const obj = {
                            code: item.code,
                            nameRu: item.nameRu,
                            nameKk: item.nameKk,
                            nameEn: item.nameEn
                        }
                        arr.push(obj);
                    }
                }
                return arr;
            }   //--Делаю каркас
            function getUniqNormativeObjects(arr, items, chosenYear) {
                let filteredObjectArr = arr.reduce((enteredArr, current) => {
                    const x = enteredArr.find(item => item.code === current.code);
                    if (!x) {
                        return enteredArr.concat([current]);
                    } else {
                        return enteredArr;
                    }
                }, []); //--создаем массив без дублей, только с code и name_ru

                for (const arrObj of filteredObjectArr) {
                    arrObj.list = [];
                    for (let year = chosenYear; year <= chosenYear + 2; year++) {
                        const valObj = isYearWithValInList(items, arrObj.code, year);
                        arrObj.list.push(valObj);
                    }
                }   //---В массив добавил status=(true/false), год и значения
                /*
                 console.log('items: ' + JSON.stringify(items));
                 console.log('filteredObjectArr_Init: ' + JSON.stringify(filteredObjectArr));
                 */
                for (const upperLevel of filteredObjectArr){
                    for (const lowerLevel of upperLevel.list){
                        if (lowerLevel.status === false){
                            //--Если в begDate нет нужного года, то начинается проверка
                            const itemsVals = getItemWithNullVal(items, lowerLevel.year, upperLevel.code);
                            if (JSON.stringify(itemsVals)!=='{}') {
                                lowerLevel.value = itemsVals.value;
                            }
                        }
                    }
                }
                // console.log('filteredObjectArr_after: ' + JSON.stringify(filteredObjectArr));
                filteredObjectArr = generateMainArr(filteredObjectArr, chosenYear); //--Основной массив с коэффициентами на каждый год
                // console.log('filteredObjectArr_final: ' + JSON.stringify(filteredObjectArr));

                return filteredObjectArr;
            }   //--Основной массив с коэффициентами на каждый год
            function isYearWithValInList(items, code, year){
                for (const item of items){
                    if (item.code == code && new Date(item.begDate).getFullYear() === year){
                        return {
                            year: year,
                            status: true,
                            value: item.value
                        }
                    }
                }
                return {
                    year: year,
                    status: false,
                    value: 0
                };
            }//---Если у года есть значение то получаем значение и status=true иначе значение 0 и status=false
            function getItemWithNullVal(items, year, code){
                if (items.length > 0){
                    for (const item of items){
                        if (item.code == code){
                            const isYearWithin = isYearWithinBegAndEndDate(code, year, item.begDate, item.endDate);
                            if (isYearWithin){
                                return item;
                            }
                        }
                    }
                }
                return {};
            }   //--Если в указанном code год не имеет значение, то проверка года происходит по endDate
            function isYearWithinBegAndEndDate(code, year, begDate, endDate){
                if (endDate !== null) {
                    //--Если столбец endDate не пустой, то выполняется проверка
                    for (let i = parseInt(new Date(begDate).getFullYear()); i < parseInt(new Date(endDate).getFullYear()); i++) {
                        if (parseInt(year) === parseInt(i)) {
                            return true;
                        }
                    }
                } else {
                    //---иначе берутся данные из begDate
                    return true;
                }
                return false;
            }   //---Если необходимый год отсутвует в begDate, то данный год ищем в endDate и подставляем к нему значение, которое соотв. году из begDate
            function generateMainArr(filteredObjectArr, year){
                if (filteredObjectArr.length > 0){
                    for (const upperObj of filteredObjectArr){
                        for (const lowerObj of upperObj.list){
                            if (parseInt(lowerObj.year) === parseInt(year)){
                                upperObj.firstYear = lowerObj.year;
                                upperObj.firstVal = lowerObj.value;
                            } else if (parseInt(lowerObj.year) === parseInt(year) + 1){
                                upperObj.secondYear = lowerObj.year;
                                upperObj.secondVal = lowerObj.value;
                            } else if (parseInt(lowerObj.year) === parseInt(year) + 2){
                                upperObj.threeYear = lowerObj.year;
                                upperObj.thirdVal = lowerObj.value;
                            }
                        }
                    }
                }
                return filteredObjectArr;
            }   //---Генерируем основной массив для специфик 116, 121, 122, 124
        },
        //---Извлекаем данные из сервиса по 159 форме, а также структурируем согласно показателям каждого года
        async getSpecificDataForSpf159(header, refinedPlan, budgetItem) {
            const region = this.getRegionForKato(this.header.regDetails);
            const curRegion = this.currentRegion;
            // console.log('region: ' + region + ' curRegion: ' + curRegion);
            const year = parseInt(header.year);
            const abp = header.abp;
            const prg = header.prg;
            const ppr = header.ppr;
            const spf = this.spfSpecificModal.modalSpecifics[1].spf;
            const response = await fetch('/api/specifics/spf159/' + '/' +year+'/'+abp+'/'+prg+'/'+ppr+'/'+spf+'/'+curRegion+'/'+region+'/'+refinedPlan);
            const itemsArr = await response.json();

            const structuredObjArr = buildArrWithObjForThreeYears(itemsArr, this.header.year, budgetItem);
            const count = countValues(structuredObjArr);
            return count;

            function buildArrWithObjForThreeYears(dataFromDB, chosenYear, budgetItem) {
                const objArr = [];
                if (dataFromDB.length > 0) {
                    for (const data of dataFromDB) {
                        // console.log('data: ' + JSON.stringify(data));
                        const obj = {
                            index: data.index,
                            nameRu: data.nameRu,
                            nameKk: data.nameKk,
                            nameEn: data.nameEn,
                            inputFormId: data.inputFormId,
                            value: data.value,
                            spf: budgetItem.spf,
                            commService: data.commService,
                            initObj: budgetItem,
                            firstYear: '',
                            firstVal: 0,
                            secondYear: '',
                            secondVal: '',
                            thirdYear: '',
                            thirdVal: 0,
                            countFirstVal: 0,
                            countSecondVal: 0,
                            countThirdVal: 0
                        }
                        for (let year = parseInt(chosenYear); year <= parseInt(chosenYear) + 2; year++) {
                            if (year === parseInt(chosenYear)){
                                obj.firstYear = year;
                                obj.firstVal = getListOfGarbageService(year, data.inputFormId, data.inputForm);
                            } else if (year === parseInt(chosenYear)+1){
                                obj.secondYear = year;
                                obj.secondVal = getListOfGarbageService(year, data.inputFormId, data.inputForm);
                            } else if (year === parseInt(chosenYear)+2){
                                obj.thirdYear = year;
                                obj.thirdVal = getListOfGarbageService(year, data.inputFormId, data.inputForm);
                            }
                        }
                        objArr.push(obj);
                    }
                }
                return objArr;
            }
            function getListOfGarbageService(year, inputFormId, inputForm){
                if (inputForm.length > 0) {
                    for (const input of inputForm) {
                        if (parseInt(new Date(input.date).getFullYear()) === parseInt(year) && (parseInt(input.inputFormIndicatorId) === parseInt(inputFormId))) {
                            return parseFloat(input.value).toFixed(2);
                        }
                    }
                }
                return parseFloat('0').toFixed(2);
            }   //---Извлекает значение согласно году
            function countValues(initArr){
                if (initArr.length > 0){
                    for (const init of initArr){
                        const countFirstVal = parseFloat(init.value) + (parseFloat(init.value) * parseFloat(init.firstVal))/parseInt(100);
                        const countSecondVal = parseFloat(countFirstVal) + (parseFloat(countFirstVal) * parseFloat(init.secondVal))/parseInt(100);
                        const countThirdVal = parseFloat(countSecondVal) + (parseFloat(countSecondVal) * parseFloat(init.thirdVal))/parseInt(100);
                        init.countFirstVal = parseFloat(countFirstVal).toFixed(2);
                        init.countSecondVal = parseFloat(countSecondVal).toFixed(2);
                        init.countThirdVal = parseFloat(countThirdVal).toFixed(2);
                    }
                }
                return initArr;
            }   //--Расчёт данных из БД по формуле
        },

        getRegionForKato(regionStr) {
            if (regionStr !== '' && regionStr.endsWith('01')) {
                const oblastEnd = '0101';
                const regionEnd = '01';
                const sizeOfWord = regionStr.length;
                const getStartIndx = (endOfRegion) => parseInt(sizeOfWord) - endOfRegion.length;
                const getHeadOfWord = (word, start, end) => word.substring(start, end);
                let regionChanged = '';

                if (regionStr.endsWith(oblastEnd)){
                    const headOfWord = getHeadOfWord(regionStr, 0, getStartIndx(oblastEnd));
                    regionChanged = headOfWord + '0000';
                } else if (regionStr.endsWith(regionEnd)) {
                    const headOfWord = getHeadOfWord(regionStr, 0, getStartIndx(regionEnd));
                    regionChanged = headOfWord + '00';
                }
                return regionChanged;
            } else {
                return regionStr;
            }
        },

        async getListOfFormLimit(year) {
            try {
                // --------Извлекаются сведения из таблицы "budget_form_limits"----//
                const response = await fetch('/api-py/budget_form_lim/' + year);
                const items = await response.json();
                let spfs = '';
                for (let i = 0; i < items.length; i++) {
                    spfs = items[i].spf;
                    const details = {
                        spf: spfs
                    }
                    this.fullInformationListArr.push(details);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса извлечение "spf" из  "budget_form_limits" ', error.toString());
            }
        },

        padLeadingZeros(num, size) {    // -------добавление нулей перед значением в зависимости от размера значения
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        concatValues(num, size) { // -------Объединяет значения в одну переменную
            let s = String(num);
            while (s.length < size) { s += s; }
            return s;
        },

        getRowKey(row, keys) {  // ----------
            const elArr = [];
            let key = '';
            for (const k of keys) {
                key = this.concatValues(row[k], 1);
                elArr.push(key);
            }
            return elArr;
        }, // преобразует значения выбранных полей в код

        async getFieldsOfFactAndFin(header, inflationObj)
        {   // -----Основной метод для извлечения всех справочников для последующего отображения в грид таблице---//
            const yearFact = (header.year - 2).toString();
            const yearPlan = (header.year - 1).toString();
            const year = header.year.toString();
            const obl = this.obl + '0000';
            const region = this.currentRegion;
            const abp = header.abp!=null ? this.padLeadingZeros(header.abp, 3): 0;
            const prg = header.prg!=null ? this.padLeadingZeros(header.prg, 3): 0;
            const ppr = header.ppr!=null ? this.padLeadingZeros(header.ppr, 3) : 0;
            this.year = year;
            this.abp = abp;
            this.prg = prg;
            this.ppr = header.ppr;
            const prognozSumArr = [];
            let listOfFact = [];
            let listOfPlan = [];
            let listOfLimits = [];
            const budgetArr = [];
            const abpVal = header.abp != null ? header.abp : 0;
            const prgVal = header.prg != null ? header.prg : 0;
            const pprVal = header.ppr != null ? header.ppr : 0;
            try {
                // ----------Извлечение данных из справочников-----------//
                listOfFact = fetch('/api-py/budget_fact552/' + yearFact + '/' + region + '/' + obl + '/' + abp + '/' + prg + '/' + ppr); // ---ссылка для отображения "Исполнение за (Прогнозный год-2) год"
                listOfPlan = fetch('/api-py/budget_plan_fin/' + yearPlan + '/' + region + '/' + abp + '/' + prg + '/' + ppr);// ---ссылка для отображения "Уточненный план на (Прогнозный год-1)  г. (без РБ)"
                listOfLimits = fetch('/api/budget-form-limit/' + year + '/' + region + '/' + abpVal + '/' + prgVal + '/' + pprVal);
                Promise.all([listOfFact, listOfPlan, listOfLimits]) // ---метод позволяющий одновременно обращаться через ajax по двум ссылкам
                    .then(values => {
                        return Promise.all(values.map(resp => resp.json()));
                    }).then(async ([listFact, listPlan, listLimits]) => {
                    parseInt(this.curHeader.year);
                    // console.log('listLimits: ' + JSON.stringify(listLimits));
                    //-------------------------------------------------------------------
                    this.bar = 95;
                    // --------Извлекаются все сведения из справочника "DictEbkEk"----//
                    const response = await fetch('/api-py/dict-ebk-spf-date-null/');
                    const items = await response.json();
                    // console.log('items: ' + JSON.stringify(items));
                    if (items.length > 0) {
                        const formLimitsArr = [];
                        this.bar = 100;
                        for (const item of items) {
                            let fact = 0;
                            let plan = 0;
                            let oneTimeExpenses = 0;
                            let overExpenses = 0;
                            let forecastOneYear = '';
                            let forecastTwoYear = '';
                            let forecastThreeYear = '';
                            let forecastOne = false;
                            let forecastTwo = false;
                            let forecastThree = false;
                            fact = getFact(listFact, item); //---Извлекаются данные из таблицы "budget_fact552"
                            plan = getPlan(listPlan, item); //---Извлекаются данные из таблицы "budget_plan_fin"
                            if (item.spf === this.spfSpecificModal.modalSpecifics[0].spf){
                                this.spfSpecificModal.modalSpecifics[0].title = item.name_ru;
                            }
                            if (item.spf === this.spfSpecificModal.modalSpecifics[1].spf){
                                this.spfSpecificModal.modalSpecifics[1].title = item.name_ru;
                            }
                            if (listLimits.length > 0){   //---Извлекаются данные из таблицы "budget_form_limits"
                                for (const limit of listLimits){
                                    if (parseInt(item.spf) === parseInt(limit.spf)) {  // В поля ввода "В Т.Ч. РАЗОВЫЕ РАСХОДЫ" и "В Т.Ч. РАСХОДЫ, УЧТЕННЫЕ СВЕРХ ПАРАМЕТРОВ" выводятся ранее сохраненные данные
                                        oneTimeExpenses = limit.oneTimeExpenses;
                                        overExpenses = limit.expensesInExcessOfParameters;
                                        if (limit.forecastOne){
                                            forecastOneYear = limit.forecastYearPlusOne;
                                            forecastOne = limit.forecastOne;
                                            // console.log('spf: ' + limit.spf + ' forecastOneYear: ' + forecastOneYear);
                                        }
                                        if (limit.forecastTwo){
                                            forecastTwoYear = limit.twoYearPlusForecast;
                                            forecastTwo = limit.forecastTwo;
                                            // console.log('spf: ' + limit.spf + ' forecastTwoYear: ' + forecastTwoYear);
                                        }
                                        if (limit.forecastThree){
                                            forecastThreeYear = limit.threeYearPlusForecast;
                                            forecastThree = limit.forecastThree;
                                            // console.log('spf: ' + limit.spf + ' forecastThreeYear: ' + forecastThreeYear);
                                        }
                                    }
                                }
                            }
                            let el = this.getElementsByParams(item.spf, item.name_ru, fact, plan, oneTimeExpenses, overExpenses, '', forecastOneYear, forecastOne, forecastTwoYear, forecastTwo, forecastThreeYear, forecastThree, inflationObj);
                            el = isNaNorIsFiniteValues(el);
                            budgetArr.push(el);
                            formLimitsArr.push(el);
                            if (el.spf===this.spfSpecific.spf111 || el.spf===this.spfSpecific.spf112) { prognozSumArr.push(el); }  //---Излекаются объекты с SPF 111 и 112
                            // console.log('el: '  + JSON.stringify(el));
                        }
                        const sumSpf111Spf112Obj = this.getSumPrognoz(prognozSumArr);  //---Суммируются значения прогнозов с spf: 111 и 112
                        this.budgetFactPlanArr = this.setUpdatedSpecific(budgetArr, sumSpf111Spf112Obj); //----Основной массив для передачи всего массива с объектами в грид таблицу
                        this.budgetFactPlanArr = this.budgetFactPlanArr.sort((a, b) => a.spf - b.spf);
                    } else {
                        this.makeToast('danger', 'Ошибка запроса по извлечению данных из "dict_ebk_ek" ');
                    }
                    //-----------------------------------------------------------------------------------------------------
                    this.$emit('infoSpravka', this.informationArr, this.fullInformationListArr);
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getFieldsOfFactAndFin', error.toString());
            }
            function getPlan(listPlan, item){
                if (listPlan.length > 0){   //---Извлекаются данные из таблицы "budget_plan_fin"
                    for (const planItem of listPlan) {
                        if (parseInt(item.spf) === parseInt(planItem.spf)) {
                            return planItem.sum !== '' ? planItem.sum : 0;
                        }
                    }
                }
                return 0;
            }
            function getFact(listFact, item){
                if (listFact.length > 0){   //---Извлекаются данные из таблицы "budget_fact552"
                    for (const factItem of listFact) {
                        if (parseInt(item.spf) === parseInt(factItem.spf)){
                            return (factItem.sum !== '' ? factItem.sum : 0) / 1000;
                        }
                    }
                }
                return 0;
            }
            function isNaNorIsFiniteValues(el){
                    el.factSum = !isNaN(el.factSum) ? parseFloat(el.factSum).toFixed(2) : parseFloat('0').toFixed(2);
                    el.planSum = !isNaN(el.planSum) ? parseFloat(el.planSum).toFixed(2) : parseFloat('0').toFixed(2);
                    el.oneTimeExpenses = !isNaN(el.oneTimeExpenses) ? parseFloat(el.oneTimeExpenses).toFixed(2) : parseFloat('0').toFixed(2);
                    el.refinedPlan = !isNaN(el.refinedPlan) ? parseFloat(el.refinedPlan).toFixed(2) : parseFloat('0').toFixed(2);
                    el.forecastYearPlusOne = !isNaN(el.forecastYearPlusOne) ? parseFloat(el.forecastYearPlusOne).toFixed(2) : parseFloat('0').toFixed(2);
                    el.expensesInExcessOfParameters = !isNaN(el.expensesInExcessOfParameters) ? parseFloat(el.expensesInExcessOfParameters) : parseFloat('0').toFixed(2);
                    el.percentOfGrowth = !isNaN(el.percentOfGrowth) && isFinite(el.percentOfGrowth) ? parseFloat(el.percentOfGrowth) : parseFloat('0').toFixed(2);
                    el.twoYearPlusForecast = !isNaN(el.twoYearPlusForecast) ? parseFloat(el.twoYearPlusForecast).toFixed(2) : parseFloat('0').toFixed(2);
                    el.twoYearPlusPercent = !isNaN(el.twoYearPlusPercent) && isFinite(el.twoYearPlusPercent) ? parseFloat(el.twoYearPlusPercent).toFixed(2) : parseFloat('0').toFixed(2);
                    el.threeYearPlusForecast = !isNaN(el.threeYearPlusForecast) ? parseFloat(el.threeYearPlusForecast).toFixed(2) : parseFloat('0').toFixed(2);
                    el.threeYearPlusPercent = !isNaN(el.threeYearPlusPercent) && isFinite(el.threeYearPlusPercent) ? parseFloat(el.threeYearPlusPercent).toFixed(2) : parseFloat('0').toFixed(2);
                return el;
            }   //---Дополнительный фильтр проверки на isNaN и isFinite специфик: 151 и 159
        },

        getSumPrognoz(prognozSumArr){
            let spf111 = {};
            let spf112 = {};
            if (prognozSumArr.length>0){
                for (const prognoz of prognozSumArr){
                    if (prognoz.spf === this.spfSpecific.spf111){
                        spf111 = prognoz;
                    } else if (prognoz.spf === this.spfSpecific.spf112){
                        spf112 = prognoz;
                    }
                }
                this.sumPrognoz.first = parseFloat(spf111.forecastYearPlusOne) + parseFloat(spf112.forecastYearPlusOne);
                this.sumPrognoz.second = parseFloat(spf111.twoYearPlusForecast) + parseFloat(spf112.twoYearPlusForecast);
                this.sumPrognoz.third = parseFloat(spf111.threeYearPlusForecast) + parseFloat(spf112.threeYearPlusForecast);
            }
            return this.sumPrognoz;
        },

        setUpdatedSpecific(budgetArr, sumSpf111Spf112Obj){
            if (budgetArr.length > 0){
                for (const budget of budgetArr){
                    if (this.isCoefficientForSpecific(budget.spf)){
                        budget.firstSumFirstRows = !isNaN(sumSpf111Spf112Obj.first) ? parseFloat(sumSpf111Spf112Obj.first) : parseFloat(0);
                        budget.secondSumFirstRows = !isNaN(sumSpf111Spf112Obj.second) ? parseFloat(sumSpf111Spf112Obj.second) : parseFloat(0);
                        budget.thirdSumFirstRows = !isNaN(sumSpf111Spf112Obj.third) ? parseFloat(sumSpf111Spf112Obj.third) : parseFloat(0);

                        const firstOPVR = budget.coefficients.opvr && JSON.stringify(budget.coefficients.opvr)!=='{}' && !isNaN(budget.coefficients.opvr.firstVal) ? parseFloat(budget.coefficients.opvr.firstVal) : parseFloat(0);
                        const secondOPVR = budget.coefficients.opvr && JSON.stringify(budget.coefficients.opvr)!=='{}' && !isNaN(budget.coefficients.opvr.secondVal) ? parseFloat(budget.coefficients.opvr.secondVal) : parseFloat(0);
                        const thirdOPVR = budget.coefficients.opvr && JSON.stringify(budget.coefficients.opvr)!=='{}' && !isNaN(budget.coefficients.opvr.thirdVal) ? parseFloat(budget.coefficients.opvr.thirdVal) : parseFloat(0);

                        const firstOPV = budget.coefficients.opv && JSON.stringify(budget.coefficients.opv)!=='{}' && !isNaN(budget.coefficients.opv.firstVal) ? parseFloat(budget.coefficients.opv.firstVal) : parseFloat(0);
                        const secondOPV = budget.coefficients.opv && JSON.stringify(budget.coefficients.opv)!=='{}' && !isNaN(budget.coefficients.opv.secondVal) ? parseFloat(budget.coefficients.opv.secondVal) : parseFloat(0);
                        const thirdOPV = budget.coefficients.opv && JSON.stringify(budget.coefficients.opv)!=='{}' && !isNaN(budget.coefficients.opv.thirdVal) ? parseFloat(budget.coefficients.opv.thirdVal) : parseFloat(0);

                        const firstSn = budget.coefficients.sn && JSON.stringify(budget.coefficients.sn)!=='{}' && !isNaN(budget.coefficients.sn.firstVal) ? parseFloat(budget.coefficients.sn.firstVal) : parseFloat(0);
                        const secondSn = budget.coefficients.sn && JSON.stringify(budget.coefficients.sn)!=='{}' && !isNaN(budget.coefficients.sn.secondVal) ? parseFloat(budget.coefficients.sn.secondVal) : parseFloat(0);
                        const thirdSn = budget.coefficients.sn && JSON.stringify(budget.coefficients.sn)!=='{}' && !isNaN(budget.coefficients.sn.thirdVal) ? parseFloat(budget.coefficients.sn.thirdVal) : parseFloat(0);

                        const firstSo = budget.coefficients.so && JSON.stringify(budget.coefficients.so)!=='{}' && !isNaN(budget.coefficients.so.firstVal) ? parseFloat(budget.coefficients.so.firstVal) : parseFloat(0);
                        const secondSo = budget.coefficients.so && JSON.stringify(budget.coefficients.so)!=='{}' && !isNaN(budget.coefficients.so.secondVal) ? parseFloat(budget.coefficients.so.secondVal) : parseFloat(0);
                        const thirdSo = budget.coefficients.so && JSON.stringify(budget.coefficients.so)!=='{}' && !isNaN(budget.coefficients.so.thirdVal) ? parseFloat(budget.coefficients.so.thirdVal) : parseFloat(0);

                        const firstOsms = budget.coefficients.osms && JSON.stringify(budget.coefficients.osms)!=='{}' && !isNaN(budget.coefficients.osms.firstVal) ? parseFloat(budget.coefficients.osms.firstVal) : parseFloat(0);
                        const secondOsms = budget.coefficients.osms && JSON.stringify(budget.coefficients.osms)!=='{}' && !isNaN(budget.coefficients.osms.secondVal) ? parseFloat(budget.coefficients.osms.secondVal) : parseFloat(0);
                        const thirdOsms = budget.coefficients.osms && JSON.stringify(budget.coefficients.osms)!=='{}' && !isNaN(budget.coefficients.osms.thirdVal) ? parseFloat(budget.coefficients.osms.thirdVal) : parseFloat(0);

                        if (budget.spf === this.spf116 && (budget.forecastOne!==true || budget.forecastTwo!==true || budget.forecastThree!==true)){
                            budget.forecastYearPlusOne = parseFloat((budget.firstSumFirstRows) * parseFloat(firstOPVR)).toFixed(2);
                            budget.twoYearPlusForecast =  parseFloat(parseFloat(budget.secondSumFirstRows) * parseFloat(secondOPVR)).toFixed(2);
                            budget.threeYearPlusForecast = parseFloat(parseFloat(budget.thirdSumFirstRows) * parseFloat(thirdOPVR)).toFixed(2);
                        } else if (budget.spf === this.spf121 && (budget.forecastOne!==true || budget.forecastTwo!==true || budget.forecastThree!==true)){
                            budget.forecastYearPlusOne = parseFloat((parseFloat(budget.firstSumFirstRows) - (parseFloat(budget.firstSumFirstRows) * parseFloat(firstOPV))) * (parseFloat(firstSn) - parseFloat(firstSo))).toFixed(2);
                            budget.twoYearPlusForecast = parseFloat((parseFloat(budget.secondSumFirstRows) - (parseFloat(budget.secondSumFirstRows) * parseFloat(secondOPV))) * (parseFloat(secondSn) - parseFloat(secondSo))).toFixed(2);
                            budget.threeYearPlusForecast = parseFloat((parseFloat(budget.thirdSumFirstRows) - (parseFloat(budget.thirdSumFirstRows) * parseFloat(thirdOPV))) * (parseFloat(thirdSn) - parseFloat(thirdSo))).toFixed(2);
                        } else if (budget.spf === this.spf122 && (budget.forecastOne!==true || budget.forecastTwo!==true || budget.forecastThree!==true)){
                            budget.forecastYearPlusOne = parseFloat((parseFloat(budget.firstSumFirstRows) - (parseFloat(budget.firstSumFirstRows) * parseFloat(firstOPV))) * parseFloat(firstSo)).toFixed(2);
                            budget.twoYearPlusForecast = parseFloat((parseFloat(budget.secondSumFirstRows) - (parseFloat(budget.secondSumFirstRows) * parseFloat(secondOPV))) * parseFloat(secondSo)).toFixed(2);
                            budget.threeYearPlusForecast = parseFloat((parseFloat(budget.thirdSumFirstRows) - (parseFloat(budget.thirdSumFirstRows) * parseFloat(thirdOPV))) * parseFloat(thirdSo)).toFixed(2);
                        } else if (budget.spf === this.spf124 && (budget.forecastOne!==true || budget.forecastTwo!==true || budget.forecastThree!==true)){
                            budget.forecastYearPlusOne = parseFloat((budget.firstSumFirstRows) * parseFloat(firstOsms)).toFixed(2);
                            budget.twoYearPlusForecast =  parseFloat(parseFloat(budget.secondSumFirstRows) * parseFloat(secondOsms)).toFixed(2);
                            budget.threeYearPlusForecast = parseFloat(parseFloat(budget.thirdSumFirstRows) * parseFloat(thirdOsms)).toFixed(2);
                        }
                    }
                }
            }
            return budgetArr;
        },

        //------Заполнение каждой строки таблицы при фильтре----//
        getElementsByParams(spf, title, fact, plan, oneTimeExpenses, overExpenses, valTable, forecastOneYear, oneForecast, forecastTwoYear, twoForecast, forecastThreeYear, threeForecast, inflationObj) {
            const yearOneKato = parseInt(this.curHeader.year);
            const yearTwoKato = parseInt(this.curHeader.year)+1;
            const yearThreeKato = parseInt(this.curHeader.year)+2;
            const informationArr = this.informationArr;
            const commServiceObj = {
                'spf151': this.spfSpecificModal.modalSpecifics[0].spf,
                'spf159': this.spfSpecificModal.modalSpecifics[1].spf
            }

            //-------------------------------------------------------------------------------------//
            const el = {
                spf: spf,
                title: title,
                factSum: parseFloat(fact).toFixed(2),
                planSum: parseFloat(plan).toFixed(2),
                oneTimeExpenses: oneTimeExpenses,
                refinedPlan: (parseFloat(plan) - parseFloat(oneTimeExpenses)).toFixed(2),
                forecastYearPlusOne: oneForecast === true ? forecastOneYear : parseFloat("0").toFixed(2), //---Брать значения из БД, если записи ранее были изменены и сохранены
                forecastOne: oneForecast,
                expensesInExcessOfParameters: overExpenses,
                percentOfGrowth: '',
                twoYearPlusForecast: twoForecast === true ? forecastTwoYear : parseFloat("0").toFixed(2), //---Брать значения из БД, если записи ранее были изменены и сохранены
                forecastTwo: twoForecast,
                twoYearPlusPercent: '',
                threeYearPlusForecast: threeForecast === true ? forecastThreeYear : parseFloat("0").toFixed(2), //---Брать значения из БД, если записи ранее были изменены и сохранены
                forecastThree: threeForecast,
                threeYearPlusPercent: '',
                year: this.year,
                region: this.currentRegion,
                abp: this.abp,
                prg: this.prg,
                ppr: this.ppr,
                ga: "null",
                separateSpecificOneYear: '',
                separateSpecificTwoYear: '',
                separateSpecificThreeYear: '',
                specificStatus: '',
                userId: this.userId,
                commServiceList: []
            };
            // ---------------Извлекается отдельная специфика с исключениями------------------------------------------//
            if (informationArr.length > 0){
                const isSpfInSpecificList = informationArr.some(obj => { if (parseInt(obj.spf)===parseInt(spf)) return true; else return false }); //--Есть ли spf в массиве
                if (isSpfInSpecificList) {
                    for (const specific of informationArr) {
                        if (!this.isCoefficientForSpecific(specific.spf)) {
                            el.commServiceList = specific.commServiceList;
                            if (specific.spf === spf && specific.spf !== '') {
                                if (this.isValueEmpty(specific.status)) {
                                    el.specificStatus = specific.status;
                                }
                                el.separateSpecificOneYear = specific.valYearOne === '' || isNaN(specific.valYearOne) ? parseFloat(0) : parseFloat(specific.valYearOne);
                                el.separateSpecificTwoYear = specific.valYearTwo === '' || isNaN(specific.valYearTwo) ? parseFloat(0) : parseFloat(specific.valYearTwo);
                                el.separateSpecificThreeYear = specific.valYearThree === '' || isNaN(specific.valYearThree) ? parseFloat(0) : parseFloat(specific.valYearThree);

                                // console.log('specific with spf: ' + JSON.stringify(specific) + ' oneForecast: ' + oneForecast + ' yearOneKato: ' + yearOneKato + ' specific.firstYear: ' + specific.firstYear);
                                if (oneForecast === false && parseInt(yearOneKato) === parseInt(specific.firstYear)) {
                                    // ----------Вычисление в столбце "ПРОГНОЗ НА (первый год)"----------------//
                                    el.forecastYearPlusOne = getForecastForTheFirstYear(el, specific, yearOneKato, commServiceObj);
                                }
                                if (twoForecast === false && parseInt(yearTwoKato) === parseInt(specific.secondYear)) {
                                    // ----------Вычисление в столбце "ПРОГНОЗ НА (второй год)-------------//
                                    el.twoYearPlusForecast = getForecastForTheSecondYear(el, specific, yearTwoKato, commServiceObj);
                                }
                                if (threeForecast === false && parseInt(yearThreeKato) === parseInt(specific.thirdYear)) {
                                    // ----------Вычисление в столбце "ПРОГНОЗ НА (третий год)----------------//
                                    el.threeYearPlusForecast = getForecastForTheThirdYear(el, specific, yearThreeKato, commServiceObj);
                                }
                            }
                        } else {
                            el.coefficients = setCoefficientsInSpecifics(specific);
                        }
                    }
                } else {
                    el.separateSpecificOneYear = isNaN(parseFloat(inflationObj.valYearOne)) ? parseFloat('0') : parseFloat(inflationObj.valYearOne);
                    el.separateSpecificTwoYear = isNaN(parseFloat(inflationObj.valYearTwo)) ? parseFloat('0') : parseFloat(inflationObj.valYearTwo);
                    el.separateSpecificThreeYear = isNaN(parseFloat(inflationObj.valYearThree)) ? parseFloat('0') : parseFloat(inflationObj.valYearThree);

                    if (oneForecast === false) {
                        if ((el.factSum !== "0.00" || el.planSum !== "0.00") && el.refinedPlan !== "0.00"){
                            // ----------Вычисление в столбце "ПРОГНОЗ НА (первый год)"----------------//
                            el.forecastYearPlusOne = getForecastForTheFirstYear(el, inflationObj, yearOneKato, commServiceObj);
                        }
                    }
                    if (twoForecast === false) {
                        // ----------Вычисление в столбце "ПРОГНОЗ НА (второй год)-------------//
                        el.twoYearPlusForecast = getForecastForTheSecondYear(el, inflationObj, yearTwoKato, commServiceObj);
                    }
                    if (threeForecast === false) {
                        // ----------Вычисление в столбце "ПРОГНОЗ НА (третий год)----------------//
                        el.threeYearPlusForecast = getForecastForTheThirdYear(el, inflationObj, yearThreeKato, commServiceObj);
                    }
                }
                // ---------Вычисление в столбце "% роста - 2024"
                el.percentOfGrowth = !isNaN(el.forecastYearPlusOne) && parseFloat(el.refinedPlan)!==parseFloat('0') ? parseFloat((parseFloat(el.forecastYearPlusOne) / parseFloat(el.refinedPlan)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
                // ---------Вычисление в столбце "% роста - 2025 год"
                el.twoYearPlusPercent = !isNaN(el.twoYearPlusForecast) && parseFloat(el.forecastYearPlusOne)!==parseFloat('0') ? parseFloat((parseFloat(el.twoYearPlusForecast) / parseFloat(el.forecastYearPlusOne)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
                // // ---------Вычисление в столбце "% роста - 2026 год"
                el.threeYearPlusPercent = !isNaN(el.threeYearPlusForecast) && parseFloat(el.twoYearPlusForecast)!==parseFloat('0') ? parseFloat((parseFloat(el.threeYearPlusForecast) / parseFloat(el.twoYearPlusForecast)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
            }
            return el;

            // ----------Вычисление в столбце "ПРОГНОЗ НА (первый год)"----------------//
            // FIXME: Нужно будет попробовать написать логику здесь
            function getForecastForTheFirstYear(el, specific, year, commServiceObj) {
                // console.log('el: ' + JSON.stringify(el) + ' Specific/Inflation: ' + JSON.stringify(specific));
                if ((el.factSum !== "0.00" || el.planSum !== "0.00") && el.refinedPlan !== "0.00" && (el.spf !== commServiceObj.spf151 && el.spf !== commServiceObj.spf159)) {
                    // console.log('spf: '+spf+' | Специфика:' + specific.valYearOne + ' ПРОГНОЗ НА '+year+' ГОД:');
                    return (parseFloat(el.refinedPlan) + (parseFloat(el.refinedPlan) * parseFloat(specific.valYearOne)) / parseFloat(100)).toFixed(2);
                }
                return parseFloat("0").toFixed(2);
            }
            // ----------Вычисление в столбце "ПРОГНОЗ НА (второй год)-----------------//
            function getForecastForTheSecondYear(el, specific, year, commServiceObj) {
                if ((el.factSum !== "0.00" || el.planSum !== "0.00") && el.twoYearPlusForecast !== inflationObj.valYearTwo && (el.twoYearPlusForecast !== 0 && el.twoYearPlusForecast !== 0.00) && (el.spf !== commServiceObj.spf151 && el.spf !== commServiceObj.spf159)) {
                    // console.log('spf: '+spf+' | Специфика:' + specific.valYearTwo + ' ПРОГНОЗ НА '+year+' ГОД:');
                    return (parseFloat(el.forecastYearPlusOne) + (parseFloat(el.forecastYearPlusOne) * parseFloat(specific.valYearTwo)) / parseFloat(100)).toFixed(2);
                }
                return parseFloat("0").toFixed(2);
            }
            // ----------Вычисление в столбце "ПРОГНОЗ НА (третий год)-----------------//
            function getForecastForTheThirdYear(el, specific, year, commServiceObj) {
                if ((el.factSum !== "0.00" || el.planSum !== "0.00") && el.threeYearPlusForecast !== inflationObj.valYearThree && (el.threeYearPlusForecast !== 0 && el.threeYearPlusForecast !== 0.00) && (el.spf !== commServiceObj.spf151 && el.spf !== commServiceObj.spf159)) {
                    // console.log('spf: '+spf+' | Специфика:' + specific.valYearThree + ' ПРОГНОЗ НА '+year+' ГОД:');
                    return (parseFloat(el.twoYearPlusForecast) + (parseFloat(el.twoYearPlusForecast) * parseFloat(specific.valYearThree)) / parseFloat(100)).toFixed(2);
                }
                return parseFloat("0").toFixed(2);
            }
            function setCoefficientsInSpecifics(specificObj){
                return {
                    "opvr": specificObj.coefficients.find((item) => item.code === 'OPVR'),
                    "opv": specificObj.coefficients.find((item) => item.code === 'OPV'),
                    "sn": specificObj.coefficients.find((item) => item.code === 'SN'),
                    "so": specificObj.coefficients.find((item) => item.code === 'SO'),
                    "osms": specificObj.coefficients.find((item) => item.code === 'OSMS')
                }
            }
        },
        //---Справочник spf к которым относятся коэффициенты
        isCoefficientForSpecific(spf){
            switch (spf){
                case 116:
                case 121:
                case 122:
                case 124:
                    return true;
                default:
                    return false;
            }
        },

        //---Проверка на пустое значение---
        isValueEmpty(e) {
            switch (e) {
                case "":
                case null:
                case false:
                case undefined:
                    return false;
                default:
                    return true;
            }
        },
        changeCellOneTimeExpenses(item, oneTimeExpenses){
            const isSpfInSpecificList = this.informationArr.some(obj => { if (obj.spf===item.spf) return true; else return false }); //--Есть ли spf в массиве
            item.oneTimeExpenses = oneTimeExpenses;
            const raschet = parseFloat(item.planSum) - parseFloat(item.oneTimeExpenses);
            const refinedPlan = !isNaN(raschet) ? parseFloat(raschet).toFixed(2) : parseFloat('0').toFixed(2);
            item.refinedPlan = refinedPlan;

            if (!isSpfInSpecificList) {
                //--ПРОГНОЗ НА 2024
                const specificValFirst = this.isValueEmpty(item.separateSpecificOneYear) ? parseFloat(item.separateSpecificOneYear) : parseFloat('0');
                const calcFirstForecast = !isNaN(refinedPlan) ? parseFloat(refinedPlan) + (parseFloat(refinedPlan) * parseFloat(specificValFirst)) / parseFloat(100) : parseFloat('0').toFixed(2);
                item.forecastYearPlusOne = parseFloat(calcFirstForecast).toFixed(2);
                const percentOfFirstYear = !isNaN(item.forecastYearPlusOne) && parseFloat(item.refinedPlan)!==parseFloat('0') ? (parseFloat(item.forecastYearPlusOne) / parseFloat(item.refinedPlan)) * parseFloat(100) : 0;
                item.percentOfGrowth = parseFloat(percentOfFirstYear).toFixed(2);
                //--ПРОГНОЗ НА 2025 ГОД
                const specificValSecond = this.isValueEmpty(item.separateSpecificTwoYear) ? parseFloat(item.separateSpecificTwoYear) : parseFloat('0');
                const calcSecondForecast = !isNaN(item.forecastYearPlusOne) ? parseFloat(item.forecastYearPlusOne) + ((parseFloat(item.forecastYearPlusOne) * parseFloat(specificValSecond)) / parseFloat(100)) : parseFloat('0').toFixed(2);
                item.twoYearPlusForecast = parseFloat(calcSecondForecast).toFixed(2);
                const percentOfSecondYear = !isNaN(item.twoYearPlusForecast) && parseFloat(item.forecastYearPlusOne)!==parseFloat('0') ? (parseFloat(item.twoYearPlusForecast) / parseFloat(item.forecastYearPlusOne)) * parseFloat(100) : parseFloat('0').toFixed(2);
                item.twoYearPlusPercent = parseFloat(percentOfSecondYear).toFixed(2);
                //--ПРОГНОЗ НА 2026 ГОД
                const specificValThird = this.isValueEmpty(item.separateSpecificThreeYear) ? parseFloat(item.separateSpecificThreeYear) : parseFloat('0');
                const calcThirdForecast = !isNaN(item.twoYearPlusForecast) ? parseFloat(item.twoYearPlusForecast) + ((parseFloat(item.twoYearPlusForecast) * parseFloat(specificValThird)) / parseFloat(100)) : parseFloat('0').toFixed(2);
                item.threeYearPlusForecast = parseFloat(calcThirdForecast).toFixed(2);
                const percentOfThirdYear = !isNaN(item.threeYearPlusForecast) && parseFloat(item.twoYearPlusForecast)!==parseFloat('0') ? (parseFloat(item.threeYearPlusForecast) / parseFloat(item.twoYearPlusForecast)) * parseFloat(100) : parseFloat('0').toFixed(2);
                item.threeYearPlusPercent = parseFloat(percentOfThirdYear).toFixed(2);
            } else {
                const percentOfFirstYear = !isNaN(item.forecastYearPlusOne) && parseFloat(item.refinedPlan)!==parseFloat('0') ? (parseFloat(item.forecastYearPlusOne) / parseFloat(item.refinedPlan)) * parseFloat(100) : 0;
                item.percentOfGrowth = parseFloat(percentOfFirstYear).toFixed(2);
            }
        },   //--В Т.Ч. РАЗОВЫЕ РАСХОДЫ
        changeCellExpenses(item, expensesInExcessOfParameters){
            item.expensesInExcessOfParameters = expensesInExcessOfParameters;
        },  //--В Т.Ч. РАСХОДЫ, УЧТЕННЫЕ СВЕРХ ПАРАМЕТРОВ
        changeCellForecast(item, forecastYearWithPlusOne) {
            const inflationIndexValue = this.informationArr.find((item) => item.spf === '');
            const isSpfInSpecificList = this.informationArr.some(obj => { if (obj.spf===item.spf) return true; else return false }); //--Есть ли spf в массиве
            item.forecastYearPlusOne = forecastYearWithPlusOne;
            if (!isSpfInSpecificList) {
                //--ПРОГНОЗ НА 2025 ГОД
                const specificValSecond = this.isValueEmpty(inflationIndexValue.valYearTwo) ? parseFloat(inflationIndexValue.valYearTwo) : parseFloat('0');
                const specificValThird = this.isValueEmpty(inflationIndexValue.valYearThree) ? parseFloat(inflationIndexValue.valYearThree) : parseFloat('0');
                const calcSecondForecast = parseFloat(item.forecastYearPlusOne) + ((parseFloat(item.forecastYearPlusOne) * parseFloat(specificValSecond)) / parseFloat(100));
                item.twoYearPlusForecast = !isNaN(calcSecondForecast) && parseFloat(calcSecondForecast)!==parseFloat('0') && this.isValueEmpty(calcSecondForecast) ? parseFloat(calcSecondForecast).toFixed(2) : parseFloat('0').toFixed(2);
                const calcThirdForecast = parseFloat(item.twoYearPlusForecast) + ((parseFloat(item.twoYearPlusForecast) * parseFloat(specificValThird))/parseFloat(100));
                item.threeYearPlusForecast = !isNaN(calcThirdForecast) && parseFloat(calcThirdForecast)!==parseFloat('0') && this.isValueEmpty(calcThirdForecast) ? parseFloat(calcThirdForecast).toFixed(2) : parseFloat('0').toFixed(2);
            }
            const percentOfFirstYear = (!isNaN(item.forecastYearPlusOne) && item.forecastYearPlusOne!=='') && (item.refinedPlan!=='' && parseFloat(item.refinedPlan)!==parseFloat('0')) ? (parseFloat(item.forecastYearPlusOne) / parseFloat(item.refinedPlan)) * parseFloat(100) : parseFloat('0').toFixed(2);
            item.percentOfGrowth = parseFloat(percentOfFirstYear).toFixed(2);
            const percentOfSecondYear = (item.twoYearPlusForecast!=='' && !isNaN(item.twoYearPlusForecast)) && (item.forecastYearPlusOne!=='' && parseFloat(item.forecastYearPlusOne)!==parseFloat('0')) ? (parseFloat(item.twoYearPlusForecast) / parseFloat(item.forecastYearPlusOne)) * parseFloat(100) : parseFloat('0').toFixed(2);
            item.twoYearPlusPercent = parseFloat(percentOfSecondYear).toFixed(2);

            const percentOfThirdYear = (item.threeYearPlusForecast!=='' && !isNaN(item.threeYearPlusForecast)) && (item.twoYearPlusForecast!=='' && parseFloat(item.twoYearPlusForecast)!==parseFloat('0')) ? (parseFloat(item.threeYearPlusForecast) / parseFloat(item.twoYearPlusForecast)) * parseFloat(100) : parseFloat('0').toFixed(2);
            item.threeYearPlusPercent = parseFloat(percentOfThirdYear).toFixed(2);

            this.countSpfWithAdditionalConditions(item);
        },
        changeCellForecastTwo(item, twoYearWithPlusForecast) {
            const inflationIndexValue = this.informationArr.find((item) => item.spf === '');
            const isSpfInSpecificList = this.informationArr.some(obj => { if (obj.spf===item.spf) return true; else return false }); //--Есть ли spf в массиве
            item.twoYearPlusForecast = twoYearWithPlusForecast;
            if (!isSpfInSpecificList) {
                //--ПРОГНОЗ НА 2026 ГОД
                const specificValThird = this.isValueEmpty(inflationIndexValue.valYearThree) ? parseFloat(inflationIndexValue.valYearThree) : parseFloat('0');
                const calcThirdForecast = parseFloat(item.twoYearPlusForecast) + ((parseFloat(item.twoYearPlusForecast) * parseFloat(specificValThird))/parseFloat(100));
                item.threeYearPlusForecast = !isNaN(calcThirdForecast) && parseFloat(calcThirdForecast)!==parseFloat('0') && this.isValueEmpty(calcThirdForecast) ? parseFloat(calcThirdForecast).toFixed(2) : parseFloat('0').toFixed(2);
            }
            const percentOfSecondYear = (item.twoYearPlusForecast!=='' && !isNaN(item.twoYearPlusForecast)) && (item.forecastYearPlusOne!=='' && parseFloat(item.forecastYearPlusOne)!==parseFloat('0')) ? (parseFloat(item.twoYearPlusForecast) / parseFloat(item.forecastYearPlusOne)) * parseFloat(100) : parseFloat('0').toFixed(2);
            item.twoYearPlusPercent = parseFloat(percentOfSecondYear).toFixed(2);

            const percentOfThirdYear = (item.threeYearPlusForecast!=='' && !isNaN(item.threeYearPlusForecast)) && (item.twoYearPlusForecast!=='' && parseFloat(item.twoYearPlusForecast)!==parseFloat('0')) ? (parseFloat(item.threeYearPlusForecast) / parseFloat(item.twoYearPlusForecast)) * parseFloat(100) : parseFloat('0').toFixed(2);
            item.threeYearPlusPercent = parseFloat(percentOfThirdYear).toFixed(2);

            this.countSpfWithAdditionalConditions(item);
        },
        changeCellForecastThree(item, threeYearWithPlusForecast){
            item.threeYearPlusForecast = threeYearWithPlusForecast;
            //--% РОСТА 2026 ГОД
            const percentOfThirdYear = (item.threeYearPlusForecast!=='' && !isNaN(item.threeYearPlusForecast)) && (item.twoYearPlusForecast!=='' && parseFloat(item.twoYearPlusForecast)!==parseFloat('0')) ? (parseFloat(item.threeYearPlusForecast) / parseFloat(item.twoYearPlusForecast)) * parseFloat(100) : parseFloat('0').toFixed(2);
            item.threeYearPlusPercent = parseFloat(percentOfThirdYear).toFixed(2);

            this.countSpfWithAdditionalConditions(item);
        },
        /*
            FIXME:При ручном изменении значения в spf 111 или 112 происходит перерасчёт только тех прогнозов,
                  чьи spf входят в список дополнительных условий.
         */
        countSpfWithAdditionalConditions(item){
            const spf111 = this.spfSpecific.spf111;
            const spf112 = this.spfSpecific.spf112;
            let spf111Obj = {};
            let spf112Obj = {};

            if (parseInt(item.spf) === spf111 || parseInt(item.spf) === spf112){
                spf111Obj = this.budgetFactPlanArr.find(({spf}) => spf === spf111);
                spf112Obj = this.budgetFactPlanArr.find(({spf}) => spf === spf112);
                const forecastYearPlusOneSpf111 = spf111Obj.forecastYearPlusOne!=='' ? parseFloat(spf111Obj.forecastYearPlusOne) : parseFloat('0');
                const forecastYearPlusOneSpf112 = spf112Obj.forecastYearPlusOne!=='' ? parseFloat(spf112Obj.forecastYearPlusOne) : parseFloat('0');
                this.sumPrognoz.first = forecastYearPlusOneSpf111 + forecastYearPlusOneSpf112;
                const twoYearPlusForecastSpf111 = spf111Obj.twoYearPlusForecast!=='' ? parseFloat(spf111Obj.twoYearPlusForecast) : parseFloat('0');
                const twoYearPlusForecastSpf112 = spf112Obj.threeYearPlusForecast!=='' ? parseFloat(spf112Obj.threeYearPlusForecast) : parseFloat('0');
                this.sumPrognoz.second = twoYearPlusForecastSpf111 + twoYearPlusForecastSpf112;
                const threeYearPlusForecastSpf111 = spf111Obj.threeYearPlusForecast!=='' ? parseFloat(spf111Obj.threeYearPlusForecast) : parseFloat('0');
                const threeYearPlusForecastSpf112 = spf112Obj.threeYearPlusForecast!=='' ? parseFloat(spf112Obj.threeYearPlusForecast) : parseFloat('0');
                this.sumPrognoz.third = threeYearPlusForecastSpf111 + threeYearPlusForecastSpf112;
                // console.log('this.sumPrognoz: ' + JSON.stringify(this.sumPrognoz));
                this.budgetFactPlanArr = this.setUpdatedSpecific(this.budgetFactPlanArr, this.sumPrognoz); //----Основной массив для передачи всего массива с объектами в грид таблицу
            }
        },
        //--Получить либо сгенерировать список комм.услуг с расчётами, которые отображаются в форме spf: 151
        async getUtilityServices(show, budgetItem) {
            // console.log('budgetItem: ' + JSON.stringify(budgetItem.commServiceList));
            let arrFromDB = await this.getListOfCommServicesFromDB(this.spfSpecificModal.modalSpecifics[0].spf); //---Ранее сохраненные ком.услуги формы 151
            const items = await this.getListOfBudgetRequestForms();
            // console.log('items: ' + JSON.stringify(items));
            if (items.length>0) {
                items.forEach(item => item.dict_utilities_type_id = this.getDictUtilitiesTypeId(item.code));
                for (const item of items) {
                    if (budgetItem.commServiceList.length > 0) {
                        for (const commService of budgetItem.commServiceList) {
                            if (item.dict_utilities_type_id === commService.indicatorDictFirstItemId && item.firstYear === new Date(commService.date).getFullYear()) {
                                item.valOne = commService.value;
                            } else if (item.dict_utilities_type_id === commService.indicatorDictFirstItemId && item.secondYear === new Date(commService.date).getFullYear()) {
                                item.secondVal = commService.value;
                            } else if (item.dict_utilities_type_id === commService.indicatorDictFirstItemId && item.thirdYear === new Date(commService.date).getFullYear()) {
                                item.thirdVal = commService.value;
                            }
                        }
                    }
                }
                const commServicesArr = countValues(items);
                // console.log('commServicesArr: ' + JSON.stringify(commServicesArr));

                arrFromDB = getArrWithObjectsFromDB(arrFromDB, commServicesArr);
                if (arrFromDB.length > 0) {
                    this.commServicesArr = arrFromDB;
                } else {
                    this.commServicesArr = commServicesArr;
                }
                const totalResults = countTotalResults(items);
                this.spf151ObjWithTotalResults = getObjectWithTotalResult(budgetItem, totalResults);
            }
            this.modalUtilityServicesVisible = show;

            function countValues(items){
                if (items.length > 0){
                    for (const init of items){
                        const countFirstVal = parseFloat(init.value) + (parseFloat(init.value) * parseFloat(init.valOne))/parseInt(100);
                        const countSecondVal = parseFloat(countFirstVal) + (parseFloat(countFirstVal) * parseFloat(init.secondVal))/parseInt(100);
                        const countThirdVal = parseFloat(countSecondVal) + (parseFloat(countSecondVal) * parseFloat(init.thirdVal))/parseInt(100);
                        init.countFirstVal = parseFloat(countFirstVal).toFixed(2);
                        init.countSecondVal = parseFloat(countSecondVal).toFixed(2);
                        init.countThirdVal = parseFloat(countThirdVal).toFixed(2);
                    }
                }
                return items;
            }
            function getArrWithObjectsFromDB(arrFromDB, commServicesArr){
                const arr = [];
                if (arrFromDB.length > 0 && commServicesArr.length > 0){
                    for (const initFromDB of arrFromDB){
                        for (const com of commServicesArr) {
                            if (initFromDB.commService === com.code && initFromDB.year === com.firstYear) {
                                const obj = {
                                    index: com.index,
                                    nameRu: com.nameRu,
                                    nameKk: com.nameKk,
                                    nameEn: com.nameEn,
                                    code: com.code,
                                    value: com.value,
                                    dict_utilities_type_id: com.dict_utilities_type_id,
                                    firstYear: com.firstYear,
                                    valOne: com.valOne,
                                    secondYear: com.secondYear,
                                    secondVal: com.secondVal,
                                    thirdYear: com.thirdYear,
                                    thirdVal: com.thirdVal,
                                    countFirstVal: initFromDB.forecastForYear,
                                    countSecondVal: initFromDB.forecastForTwoYears,
                                    countThirdVal: initFromDB.forecastForThreeYears
                                }
                                arr.push(obj);
                            }
                        }
                    }
                }
                return arr;
            }
            function countTotalResults(initArr){
                const totalObj = {}
                if (initArr.length > 0){
                    totalObj.totalCount = 0;
                    totalObj.totalForecastOne = 0;
                    totalObj.totalForecastTwo = 0;
                    totalObj.totalForecastThree = 0;
                    for (const init of initArr){
                        totalObj.totalCount += parseFloat(init.value);
                        totalObj.totalForecastOne += parseFloat(init.countFirstVal);
                        totalObj.totalForecastTwo += parseFloat(init.countSecondVal);
                        totalObj.totalForecastThree += parseFloat(init.countThirdVal);
                    }
                }
                return totalObj;
            }
            //---Получаем объект с финальными показателями spf:151 для записи "budget_form_limits"
            function getObjectWithTotalResult(budgetItem, totalResults){
                // ---------Вычисление в столбце "% роста - 2024"
                const percentOfGrowth = !isNaN(totalResults.totalForecastOne) && parseFloat(budgetItem.refinedPlan)!==parseFloat('0') ? parseFloat((parseFloat(totalResults.totalForecastOne) / parseFloat(budgetItem.refinedPlan)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
                // ---------Вычисление в столбце "% роста - 2025 год"
                const twoYearPlusPercent = !isNaN(totalResults.totalForecastTwo) && parseFloat(totalResults.totalForecastOne)!==parseFloat('0') ? parseFloat((parseFloat(totalResults.totalForecastTwo) / parseFloat(totalResults.totalForecastOne)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
                // // ---------Вычисление в столбце "% роста - 2026 год"
                const threeYearPlusPercent = !isNaN(totalResults.totalForecastThree) && parseFloat(totalResults.totalForecastTwo)!==parseFloat('0') ? parseFloat((parseFloat(totalResults.totalForecastThree) / parseFloat(totalResults.totalForecastTwo)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
                return {
                    "spf": budgetItem.spf,
                    "title": budgetItem.title,
                    "factSum": parseFloat(budgetItem.factSum).toFixed(2),
                    "planSum": parseFloat(budgetItem.planSum).toFixed(2),
                    "oneTimeExpenses": parseFloat(budgetItem.oneTimeExpenses).toFixed(2),
                    "refinedPlan": parseFloat(budgetItem.refinedPlan).toFixed(2),
                    "forecastYearPlusOne": parseFloat(totalResults.totalForecastOne).toFixed(2),
                    "forecastOne": true,
                    "expensesInExcessOfParameters": parseFloat(budgetItem.expensesInExcessOfParameters).toFixed(2),
                    "percentOfGrowth": parseFloat(percentOfGrowth).toFixed(2), //-----
                    "twoYearPlusForecast": parseFloat(totalResults.totalForecastTwo).toFixed(2),
                    "forecastTwo": true,
                    "twoYearPlusPercent": parseFloat(twoYearPlusPercent).toFixed(2), //-----
                    "threeYearPlusForecast": parseFloat(totalResults.totalForecastThree).toFixed(2),
                    "forecastThree": true,
                    "threeYearPlusPercent": parseFloat(threeYearPlusPercent).toFixed(2), //-----
                    "year": parseInt(budgetItem.year),
                    "region": budgetItem.region,
                    "abp": budgetItem.abp,
                    "prg": budgetItem.prg,
                    "ppr": budgetItem.ppr,
                    "ga": budgetItem.ga,
                    "userId": budgetItem.userId
                }
            }
        },

        //--Получить либо сгенерировать список услуг по выбросу мусора с расчётами, которые отображаются в форме spf: 159
        async getOtherServices(show, budgetItem) {
            // console.log('budgetItem: ' + JSON.stringify(budgetItem));
            const mainObjArr = await this.getSpecificDataForSpf159(this.header, budgetItem.refinedPlan, budgetItem);
            // console.log('mainObjArr: ' + JSON.stringify(mainObjArr));
            const arrFromDB = await this.getListOfCommServicesFromDB(this.spfSpecificModal.modalSpecifics[1].spf); //---Ранее сохраненные ком.услуги формы 159
            // console.log('arrFromDB: ' + JSON.stringify(arrFromDB));
            if (arrFromDB.length > 0){
                this.form159 = getGarbageDataFromDB(arrFromDB, mainObjArr, budgetItem);   //--Формирую объект из БД для отображения
                // console.log('FROM_DB_this.form159: ' + JSON.stringify(this.form159));
            } else {
                this.form159 = mainObjArr;
                // console.log('Generated_this.form159: ' + JSON.stringify(this.form159));
            }
            this.form159 = this.form159.sort((a, b) => a.index - b.index);
            if (show) {
                this.modalOtherServicesVisible = show;
            }

            function getGarbageDataFromDB(arrFromDB, mainObjArr, budgetItem){
                const arr = [];
                if (arrFromDB.length > 0 && mainObjArr.length > 0){
                    for (const initFromDB of arrFromDB){
                        for (const mainObj of mainObjArr){
                            if (initFromDB.commService === mainObj.commService && initFromDB.year === mainObj.firstYear){
                                const obj = {
                                    index: mainObj.index,
                                    nameRu: mainObj.nameRu,
                                    nameKk: mainObj.nameKk,
                                    nameEn: mainObj.nameEn,
                                    inputFormId: mainObj.inputFormId,
                                    value: mainObj.value,
                                    spf: mainObj.spf,
                                    commService: mainObj.commService,
                                    initObj: budgetItem,
                                    firstYear: mainObj.firstYear,
                                    firstVal: mainObj.firstVal,
                                    secondYear: mainObj.secondYear,
                                    secondVal: mainObj.secondVal,
                                    thirdYear: mainObj.thirdYear,
                                    thirdVal: mainObj.thirdVal,
                                    countFirstVal: initFromDB.forecastForYear,
                                    countSecondVal: initFromDB.forecastForTwoYears,
                                    countThirdVal: initFromDB.forecastForThreeYears
                                }
                                arr.push(obj);
                            }
                        }
                    }
                }
                return arr;
            }
        },
        //--Получаем сервис для формы spf:151
        async getListOfBudgetRequestForms(){
            const param = {
                region: this.currentRegion,
                abp: this.header.abp,
                prg: this.header.prg,
                ppr: this.header.ppr,
                spf: this.spfSpecificModal.modalSpecifics[0].spf,
                curYear: parseInt(this.header.year)-1,
                year: parseInt(this.header.year)-1
            }
            const urlLink = '/api/request-form/'+param.region+'/'+param.abp+'/'+param.prg+'/'+this.header.ppr+'/'+param.spf+'/'+param.curYear+'/'+param.year
            const response = await fetch(urlLink);
            const spf151List = await response.json();
            return spf151List;
        },
        //--Получаем сервис ранее сохраненных данных в БД для формы spf:151
        async getListOfCommServicesFromDB(spf) {
            const param = {
                abp: this.header.abp,
                prg: this.header.prg,
                ppr: this.header.ppr,
                spf: spf,
                year: this.header.year,
                region: this.currentRegion,
                userName: this.userId
            }
            const urlLink = '/api/form-limits-commservice/list/' + param.abp + '/' + param.prg + '/' + this.header.ppr + '/' + param.spf + '/' + param.year + '/'+ param.region
            const response = await fetch(urlLink);
            const spf151List = await response.json();
            return spf151List;
        },
        //--Перерасчёт при вводе данных вручную в форме 151 и 159
        changeFirstYearVal(commItem, val, param){
            const countSecondVal = parseFloat(val) + (parseFloat(val) * parseFloat(commItem.secondVal))/parseInt(100);
            const countThirdVal = parseFloat(countSecondVal) + (parseFloat(countSecondVal) * parseFloat(commItem.thirdVal))/parseInt(100);
            commItem.countFirstVal = val;
            commItem.countSecondVal = !isNaN(countSecondVal) ? parseFloat(countSecondVal).toFixed(2) : parseFloat('0').toFixed(2);
            commItem.countThirdVal = !isNaN(countThirdVal) ? parseFloat(countThirdVal).toFixed(2) : parseFloat('0').toFixed(2);
        },
        changeSecondYearVal(commItem, val, param){
            const countThirdVal = parseFloat(val) + (parseFloat(val) * parseFloat(commItem.thirdVal))/parseInt(100);
            commItem.countSecondVal = val;
            commItem.countThirdVal = !isNaN(countThirdVal) ? parseFloat(countThirdVal).toFixed(2) : parseFloat('0').toFixed(2);
        },
        changeThirdYearVal(commItem, val, param){
            commItem.countThirdVal = val;
        },
        //--Сохранение комм.услуг из формы 151 в БД
        saveUtilityServices(acceptance){
            this.modalUtilityServicesVisible = acceptance;
            if (acceptance){
                this.limitsFormCommServicesArr = getCommServiceArr(this.commServicesArr, this.header, this.spfSpecificModal, this.userId, this.currentRegion);
                this.saveDataToDB('comm_service');
            }

            function getCommServiceArr(commServicesArr, header, spfSpecificModal, userId, currentRegion){
                const arr = [];
                if (commServicesArr.length > 0) {
                    for (const comService of commServicesArr) {
                        const commServicesObj = {
                            abp: parseInt(header.abp),
                            prg: parseInt(header.prg),
                            ppr: parseInt(header.ppr),
                            spf: parseInt(spfSpecificModal.modalSpecifics[0].spf),
                            commService: comService.code,
                            year: parseInt(header.year),
                            forecastForYear: parseFloat(comService.countFirstVal),
                            forecastForTwoYears: parseFloat(comService.countSecondVal),
                            forecastForThreeYears: parseFloat(comService.countThirdVal),
                            region: currentRegion,
                            userName: userId
                        }
                        arr.push(commServicesObj);
                    }
                }
                return arr;
            }
        },
        //--Сохранение услуг вывоза мусора из формы 159 в БД
        saveOtherServices(acceptance){
            this.modalOtherServicesVisible = acceptance;
            if (!this.isWarning && acceptance && this.form159.length > 0){
                this.spf159ObjWithTotalResults = getObjectWithTotalResult(this.form159);
                this.limitFormGarbageServicesArr = getObjectFormLimitsGarbageServices(this.form159);
                this.saveDataToDB('garbage_service');
            }
            //---Получаем объект с финальными показателями spf:159 для записи "budget_form_limits"
            function getObjectWithTotalResult(form159) {
                const firstIndxObj = form159[0];
                // ---------Вычисление в столбце "% роста - 2024"
                const percentOfGrowth = !isNaN(firstIndxObj.countFirstVal) && parseFloat(firstIndxObj.initObj.refinedPlan)!==parseFloat('0') ? parseFloat((parseFloat(firstIndxObj.countFirstVal) / parseFloat(firstIndxObj.initObj.refinedPlan)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
                // ---------Вычисление в столбце "% роста - 2025 год"
                const twoYearPlusPercent = !isNaN(firstIndxObj.countSecondVal) && parseFloat(firstIndxObj.countFirstVal)!==parseFloat('0') ? parseFloat((parseFloat(firstIndxObj.countSecondVal) / parseFloat(firstIndxObj.countFirstVal)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
                // // ---------Вычисление в столбце "% роста - 2026 год"
                const threeYearPlusPercent = !isNaN(firstIndxObj.countThirdVal) && parseFloat(firstIndxObj.countSecondVal)!==parseFloat('0') ? parseFloat((parseFloat(firstIndxObj.countThirdVal) / parseFloat(firstIndxObj.countSecondVal)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
                return {
                    "spf": firstIndxObj.initObj.spf,
                    "title": firstIndxObj.initObj.title,
                    "factSum": parseFloat(firstIndxObj.initObj.factSum).toFixed(2),
                    "planSum": parseFloat(firstIndxObj.initObj.planSum).toFixed(2),
                    "oneTimeExpenses": parseFloat(firstIndxObj.initObj.oneTimeExpenses).toFixed(2),
                    "refinedPlan": parseFloat(firstIndxObj.initObj.refinedPlan).toFixed(2),
                    "forecastYearPlusOne": parseFloat(firstIndxObj.countFirstVal).toFixed(2),
                    "forecastOne": true,
                    "expensesInExcessOfParameters": parseFloat(firstIndxObj.initObj.expensesInExcessOfParameters).toFixed(2),
                    "percentOfGrowth": parseFloat(percentOfGrowth).toFixed(2), //-----
                    "twoYearPlusForecast": parseFloat(firstIndxObj.countSecondVal).toFixed(2),
                    "forecastTwo": true,
                    "twoYearPlusPercent": parseFloat(twoYearPlusPercent).toFixed(2), //-----
                    "threeYearPlusForecast": parseFloat(firstIndxObj.countThirdVal).toFixed(2),
                    "forecastThree": true,
                    "threeYearPlusPercent": parseFloat(threeYearPlusPercent).toFixed(2), //-----
                    "year": parseInt(firstIndxObj.initObj.year),
                    "region": firstIndxObj.initObj.region,
                    "abp": firstIndxObj.initObj.abp,
                    "prg": firstIndxObj.initObj.prg,
                    "ppr": firstIndxObj.initObj.ppr,
                    "ga": firstIndxObj.initObj.ga,
                    "userId": firstIndxObj.initObj.userId
                }
            }
            function getObjectFormLimitsGarbageServices(form159){
                const arr = [];
                if (form159.length > 0){
                    for (const obj of form159){
                        const commServicesObj = {
                            abp: parseInt(obj.initObj.abp),
                            prg: parseInt(obj.initObj.prg),
                            ppr: parseInt(obj.initObj.ppr),
                            spf: parseInt(obj.initObj.spf),
                            commService: obj.commService,
                            year: parseInt(obj.initObj.year),
                            forecastForYear: parseFloat(obj.countFirstVal),
                            forecastForTwoYears: parseFloat(obj.countSecondVal),
                            forecastForThreeYears: parseFloat(obj.countThirdVal),
                            region: obj.initObj.region,
                            userName: obj.initObj.userId
                        }
                        arr.push(commServicesObj);
                    }
                }
                return arr;
            }
        },

        // FIXME: Нужно переделать и брать данные из таблицы input_form_indicator id=20 из столбца dict_1_items
        getDictUtilitiesTypeId(code){
            switch (code) {
                case '1.1':
                    return parseInt('21');
                case '1.2':
                    return parseInt('21');
                case '02':
                    return parseInt('22');
                case '03':
                    return parseInt('41');
                case '04':
                    return parseInt('23');
                case '05':
                    return parseInt('42');
                default:
                    return null;
            }
        },
        getSpfWithoutDuplicates(arr) {  // -----Функция для очистки массива от дубликатов
            const uniqueArray = arr.filter(function (item, pos) {
                return arr.indexOf(item) === pos;
            });
            return uniqueArray;
        },
        // -------Метод для извлечения области и региона-------//
        async getObl() {    // -----Извлекается регион и область из глобальных настроек пользователя
            // let region = null;
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                        // region = json.region;
                    });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },

        async getUsrId() {
            return await store.getters.user_uuid;
        },

        // -------Метод для отображения диалогового окна для сохранения данных в БД----//
        saveDataToDB(param) {
            this.$bvModal.msgBoxConfirm(
                'Вы действительно хотите сохранить данные?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    if (value) {
                        const listOfValuesForSave = this.compareAndFindValuesNotNull();
                        if (param === 'all') {
                            if (listOfValuesForSave.length > 0) {
                                await this.saveLimitDataToDB(listOfValuesForSave, false);
                            }
                        } else if (param === 'comm_service') {
                            const arr = [];
                            if (JSON.stringify(this.spf151ObjWithTotalResults)!=='{}') {
                                const spf151ObjWithTotalResults = this.spf151ObjWithTotalResults;
                                      spf151ObjWithTotalResults.forecastYearPlusOne = this.totalFirstCount;
                                      spf151ObjWithTotalResults.twoYearPlusForecast = this.totalSecondCount;
                                      spf151ObjWithTotalResults.threeYearPlusForecast = this.totalThirdCount;
                                arr.push(spf151ObjWithTotalResults);
                                if (this.limitsFormCommServicesArr.length > 0 && listOfValuesForSave.length > 0) {  //---New
                                    const budgetFormLimitData = this.getDataForBudgetFormLimit(listOfValuesForSave, true);  //---New
                                    const commServiceArr = this.getDataForBudgetFormLimit(arr, false);  //---New
                                    await this.saveComServiceAndLimitDataToDB(this.limitsFormCommServicesArr, budgetFormLimitData, commServiceArr);  //---New
                                }
                            }
                        } else if (param === 'garbage_service'){
                            if (JSON.stringify(this.spf159ObjWithTotalResults)!=='{}') {
                                const arr = [];
                                arr.push(this.spf159ObjWithTotalResults);
                                if (this.limitFormGarbageServicesArr.length > 0 && listOfValuesForSave.length > 0) {  //---New
                                    const budgetFormLimitData = this.getDataForBudgetFormLimit(listOfValuesForSave, true);  //---New
                                    const commServiceArr = this.getDataForBudgetFormLimit(arr, false);  //---New
                                    await this.saveComServiceAndLimitDataToDB(this.limitFormGarbageServicesArr, budgetFormLimitData, commServiceArr);  //---New
                                }
                            }
                        }
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка сохранения', error.toString());
                    this.loading = false;
                    this.bar = 0;
                });
        },

        getDataForBudgetFormLimit(arr, commService){
            return {
                "year": this.header.year,
                "region": this.currentRegion,
                "attribute": this.attributeStatus,
                "commService": commService,
                "budgetFormLimitsEntityDto": arr,
            }
        },  //---Object for budget_form_limits table in DB

        compareAndFindValuesNotNull(){
            const saveDataIntoDB = [];
            const factPlan = this.budgetFactPlanArr;
            for (const row of factPlan) {
                if (
                    this.isValueIsNotEmpty(row.oneTimeExpenses) === true &&
                    this.isValueIsNotEmpty(row.forecastYearPlusOne) === true &&
                    this.isValueIsNotEmpty(row.expensesInExcessOfParameters) === true &&
                    this.isValueIsNotEmpty(row.twoYearPlusForecast) === true &&
                    this.isValueIsNotEmpty(row.threeYearPlusForecast) === true
                ){
                    row.forecastOne = true;
                    row.forecastTwo = true;
                    row.forecastThree = true;
                    saveDataIntoDB.push(row);
                }
            }
            return saveDataIntoDB;
        },

        isValueIsNotEmpty(value) {
            if (this.isValueEmpty(value) && !isNaN(value) && isFinite(value)){
                return true;
            }
            return false
        },

        //----------Сохранение данных в БД
        async saveLimitDataToDB(arr, commService) {
            this.loading = true;
            const arrObj = {
                "year": this.header.year,
                "region": this.currentRegion,
                "attribute": this.attributeStatus,
                "commService": commService,
                "budgetFormLimitsEntityDto": arr,
            }
            if (arr.length>0){
                // console.log('arr: ' + JSON.stringify(arr));
                try {
                    const url = '/api/budget-form-limit/add';    // FIXME:Запрос будет направлен на kotlin
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(arrObj) // массив будет направлен на kotlin
                    });
                    const result = await response.json();
                    if ((response.status === 200) && (result === true)) {    // FIXME: Статус будет взят из kotlin
                        //---Извлечение всего списка из таблицы "budget_form_limits" по году---//
                        await this.getListOfFormLimit(this.header.year);
                        await this.getIndexOfInflationIndicators(this.header);
                        this.loading = false;
                        this.makeToast('Сообщение', 'Данные сохранены', '');
                        this.bar = 0;
                    }
                } catch {
                    this.loading = false;
                    this.bar = 0;
                    this.makeToast('danger', 'Ошибка сохранения данных', 'Предупреждение');
                }
            }
        },

        //----------Сохранение данные по "коммунальным услугам" spf-151 в БД
        async saveComServiceAndLimitDataToDB(arr, formLimitArr, arrDialogForm) {
            const arrObj = {
                "attribute": this.attributeStatus,
                "budgetFormLimitsComServicesEntityDto": arr,
                "formLimitsHeaderDto": formLimitArr,
                "commService": arrDialogForm
            }
            if (arr.length>0){
                this.loading = true;
                this.modalUtilityServicesVisible = false;
                this.modalOtherServicesVisible = false;
                try {
                    const url = '/api/form-limits-commservice/add';
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(arrObj)
                    });
                    const result = await response.json();
                    if (result===true) {
                        //---Извлечение всего списка из таблицы "budget_form_limits" по году---//
                        await this.getListOfFormLimit(this.header.year);
                        await this.getIndexOfInflationIndicators(this.header);
                        this.loading = false;
                        this.bar = 0;
                        this.makeToast('Сообщение', 'Данные сохранены', '');
                    } else {
                        this.loading = false;
                        this.bar = 0;
                        this.makeToast('danger', 'Ошибка сохранения данных по комм.услугам', 'Предупреждение');
                    }
                } catch {
                    this.loading = false;
                    this.bar = 0;
                    this.makeToast('danger', 'Ошибка сохранения данных', 'Предупреждение');
                }
            }
        },

        makeToast(title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение с ошибкой

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // console.log('currentNode: ' + currentNode);
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // console.log('allElements: ' + allElements);
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // console.log('currentIndex: ' + currentIndex);
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            // console.log('targetIndex: ' + targetIndex);
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
            const el = allElements[currentIndex].value;
            if (isNaN(el) || !isFinite(el) || el === '' || !this.isValueEmpty(el)) {
                allElements[currentIndex].value = parseFloat(0).toFixed(2);
            }
            // console.log('value_currentIndex: ' + allElements[currentIndex].value);
            // console.log('value_targetIndex: ' + allElements[targetIndex].value);
        }, // enter работает как tab

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            // console.log('regex: ' + regex);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            // console.log('key: ' + key);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        inputFixed(item, field, value, digit) {
            if (isNaN(value) || !isFinite(value) || value === '' || !this.isValueEmpty(value)) {
                value = parseFloat(0);
            }
            this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
        }, // форматирует введенное значение до digit цифр после запятой

        openFilterByRef(refName) {
            this.$refs.budgetHeader.openFilterByRef(refName);
        }
    },

    created() {
        // this.$store.commit('loadListOfLimits', this.budgetFactPlanArr);
    },

    computed: {
        // ----ИСПОЛНЕНИЕ ЗА (ПРОГНОЗНЫЙ ГОД-2) ГОД
        factSum() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.factSum !== '' ? row.factSum : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // ----УТОЧНЕННЫЙ ПЛАН НА (ПРОГНОЗНЫЙ ГОД-1) Г. (БЕЗ РБ)
        planSum() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.planSum !== '' ? row.planSum : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // ----РАЗОВЫЕ РАСХОДЫ
        oneTimeExpenses() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.oneTimeExpenses !== '' ? row.oneTimeExpenses : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // ----УТОЧНЕННЫЙ ПЛАН НА 01.04.2021 Г. (БЕЗ УЧЕТА РАЗОВЫХ РАСХОДОВ)
        refinedPlan() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.refinedPlan !== '' ? row.refinedPlan : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // ----ПРОГНОЗ НА 2022 ГОД
        forecastYearPlusOne() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.forecastYearPlusOne !== '' ? row.forecastYearPlusOne : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // ----РАСХОДЫ, УЧТЕННЫЕ СВЕРХ ПАРАМЕТРОВ
        expensesInExcessOfParameters() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.expensesInExcessOfParameters !== '' ? row.expensesInExcessOfParameters : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // ----% РОСТА
        percentOfGrowth() {
            let sumYearOne = 0;
            let sumRefinedPlan = 0;
            for (const row of this.budgetFactPlanArr) {
                sumYearOne += parseFloat(row.forecastYearPlusOne !== '' ? row.forecastYearPlusOne : 0.00);
                sumRefinedPlan += parseFloat(row.refinedPlan !== '' ? row.refinedPlan : 0.00);
            }
            return parseFloat(sumRefinedPlan)!==0 ? parseFloat((sumYearOne / sumRefinedPlan) * 100).toFixed(2) : parseFloat('0').toFixed(2);
        },
        // -----ПРОГНОЗ 2023 ГОД
        twoYearPlusForecast() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.twoYearPlusForecast !== '' ? row.twoYearPlusForecast : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // ----ПРОГНОЗ 2024 ГОД
        threeYearPlusForecast() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.threeYearPlusForecast !== '' ? row.threeYearPlusForecast : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // ----% РОСТА 2023 ГОД
        twoYearPlusPercent() {
            let sumYearOne = 0;
            let sumYearTwo = 0;
            let percentGrowthTwo = 0;
            for (const row of this.budgetFactPlanArr) {
                sumYearOne += parseFloat(row.forecastYearPlusOne !== '' ? row.forecastYearPlusOne : 0.00);
                sumYearTwo += parseFloat(row.twoYearPlusForecast !== '' ? row.twoYearPlusForecast : 0.00);
            }
            percentGrowthTwo = parseFloat((sumYearTwo / sumYearOne) * 100).toFixed(2);
            return parseFloat(percentGrowthTwo).toFixed(2);
        },
        // ----% РОСТА 2024 ГОД
        threeYearPlusPercent() {
            let sumThreeYear = 0;
            let sumYearTwo = 0;
            let percentGrowthThree = 0;
            for (const row of this.budgetFactPlanArr) {
                sumThreeYear += parseFloat(row.threeYearPlusForecast !== '' ? row.threeYearPlusForecast : 0.00);
                sumYearTwo += parseFloat(row.twoYearPlusForecast !== '' ? row.twoYearPlusForecast : 0.00);
            }
            percentGrowthThree = parseFloat((sumThreeYear / sumYearTwo) * 100).toFixed(2);
            return parseFloat(percentGrowthThree).toFixed(2);
        },

        userId() {
            return this.$store.getters.user_uuid;
        },
        //---Итоговые значение в форме ком.услуг с spf 151
        totalValCount(){
          let sum = 0;
          for (const row of this.commServicesArr){
              sum += parseFloat(row.value!=='' ? row.value : 0.00);
          }
          return parseFloat(sum).toFixed(2);
        },
        totalFirstCount(){
            let sum = 0;
            for (const row of this.commServicesArr) {
                sum += parseFloat(row.countFirstVal !== '' ? row.countFirstVal : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        totalSecondCount(){
            let sum = 0;
            for (const row of this.commServicesArr) {
                sum += parseFloat(row.countSecondVal !== '' ? row.countSecondVal : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        totalThirdCount(){
            let sum = 0;
            for (const row of this.commServicesArr) {
                sum += parseFloat(row.countThirdVal !== '' ? row.countThirdVal : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        isWarning(){
            let firstRow = 0;
            let secondRow = 0;
            if (this.form159.length > 0){
                for (const init of this.form159){
                    if (init.index === 1){
                        firstRow = parseFloat(init.countFirstVal) + parseFloat(init.countSecondVal) + parseFloat(init.countThirdVal);
                    } else {
                        secondRow = parseFloat(init.countFirstVal) + parseFloat(init.countSecondVal) + parseFloat(init.countThirdVal);
                    }
                }
            }
            return secondRow > firstRow;
        }   //--Если Оплата вывоза ТБО больше суммы по специфике 159, то выдавать предупреждение
    },
    watch: {
        userId: async function (newVal, oldVal) {
            if (newVal!= oldVal) {
                await this.getObl();
            }
            // console.log('watch userId', newVal, oldVal)
        }
    }
};
</script>

<style scoped>
  .tempText{
      font-size: 10px; font-weight: bold;
  }
  .tempTextBubble{
      display: inline-block; /* Строчно-блочный элемент */
      position: relative; /* Относительное позиционирование */
  }
  .tempTextBubble:hover::after {
      content: attr(data-title); /* Выводим текст */
      position: absolute; /* Абсолютное позиционирование */
      left: -150px; top: 150%; /* Положение подсказки */
      z-index: 1; /* Отображаем подсказку поверх других элементов */
      background: rgba(255,255,230,0.9); /* Полупрозрачный цвет фона */
      font-family: Arial, sans-serif; /* Гарнитура шрифта */
      font-size: 24px; /* Размер текста подсказки */
      padding: 35px 50px; /* Поля */
      border: 1px solid #333; /* Параметры рамки */
  }
  .utilityServices{
      text-align: center;
  }
  .inputCommService{
      width: 80px;
  }
  .trTdCommService td:not(:first-child){
      width: 130px;
  }
  .trTdCommService td:first-child{
      width: 50px;
      text-align: center;
  }
  .warning-message{
      font-size: 14px;
      color: #df1919;
      margin: 12px;
  }
</style>